import { useNavigate } from "react-router-dom"
import { PendingSubmissionUnion, usePendingSubmission } from "../../../hooks/usePendingSubmission"
import { RequireIncidentProvided, requireIncident } from "../../../wrappers/requireIncident"
import { useCallback, useMemo, useState } from "react"
import { SubmittedSubmissionModel, isSubmitted } from "../../../reduxToolkit/submissionsSlice"
import { bySubmittedAtDesc } from "../../../../lib/util/sort"
import { raiseUnknownSubmissionType } from "../../../../types/supabase"
import { assert } from "../../../../lib/util/assert"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit"
import { isSubmittedExpense, updateExpense } from "../../../reduxToolkit/expensesSlice"
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone"
import { formatCurrency } from "../../../../lib/formatCurrency"
import { Tooltip } from "../../../components/tooltip"
import { selectPendingExpenses } from "../../../hooks/usePendingExpenses"
import { FakeProgressBar } from "../../../components/fakeProgressBar"
import { useCustomization } from "../../../hooks/useCustomizations"
import { useSentry } from "../../../providers/sentry"
import { getCurrentLimits } from "../../../../lib/rules/limits"

interface SubmitHomeProps extends RequireIncidentProvided {
}

export function SubmitHome({incident}: SubmitHomeProps) {
  const navigate = useNavigate()
  const { captureException } = useSentry()

  const [pendingSubmission, createPendingSubmission] = usePendingSubmission(incident)

  const lastSubmission = incident.submissions.filter(isSubmitted).sort(bySubmittedAtDesc)[0]
  
  const limits = getCurrentLimits()
  
  const hraCustomizations = useCustomization('hra')
  const unsubmittedExpenses =
    useAppSelector((state) =>
        state.expenses.expenses
        .filter((expense) => expense.incident_id === incident.id))
        .filter((e) => !isSubmittedExpense(e))

  const createAndNavigateToSubmission = useCallback(() => {
    assert(pendingSubmission, 'Pending submission must be defined')
    const newSubmission = createPendingSubmission(pendingSubmission)
    navigate(`/incidents/${incident.id}/submit/${newSubmission.id}`)
  }, [pendingSubmission, navigate, incident.id, createPendingSubmission])

  if (!pendingSubmission) {
    // why can't I submit?
    let why: string | null = null
    if (unsubmittedExpenses.length === 0) {
      why = 'All your expenses for this incident have already been submitted.'
    } else if (
      unsubmittedExpenses.every((e) => e.paid_with_hra === true)
    ) {
      why = 'All of your expenses have been automatically submitted to the HRA because you used your HRA card.  ' +
        `Once your expense total crosses over ${formatCurrency(limits.qualifying_amount)}, you can submit to CHM for reimbursement.`
    } else if (!hraCustomizations?.hasHra) {
      why = `The total value of your expenses is less than ${formatCurrency(limits.qualifying_amount)}, so you can't submit them for reimbursement yet.`
    } else {
      // This should not happen.  Report it to sentry, but don't blow up the user's screen.
      captureException(new Error(`Incident ${incident.id} has no pending submission, but has ${unsubmittedExpenses.length} pending expenses`),
        {
          extra: {
            incidentId: incident.id,
          }
        })
    }
    
    return <div className="row">
      <div className="col-12">
        <h2>We cannot prepare your submission for this incident yet.</h2>
        <p>
          {why}
        </p>
        <button className="btn btn-primary"
          onClick={() => navigate(`/incidents/${incident.id}`)}>Go Back</button>
      </div>
    </div>
  }

  switch(pendingSubmission.submission_type) {
    case 'CHM':
      return <PendingCHMSubmission submission={pendingSubmission} lastSubmission={lastSubmission} onBegin={createAndNavigateToSubmission} />
    case 'CHM-addon':
      return <PendingCHMAddOnSubmission submission={pendingSubmission} lastSubmission={lastSubmission} onBegin={createAndNavigateToSubmission} />
    case 'HRA':
      return <PendingHRASubmission submission={pendingSubmission} lastSubmission={lastSubmission} onBegin={createAndNavigateToSubmission} />

    case 'CHM-personal-responsibility':
      throw new Error(`Submission of type ${pendingSubmission.submission_type} should not be created as a pending submission`)
    default:
      raiseUnknownSubmissionType(pendingSubmission.submission_type)
  }
}

export default requireIncident(SubmitHome)

interface PendingSubmissionProps {
  submission: PendingSubmissionUnion,
  lastSubmission?: SubmittedSubmissionModel | null
  
  onBegin: () => void
}

function PendingCHMSubmission({submission, lastSubmission, onBegin}: PendingSubmissionProps) {
  const [beginning, setBeginning] = useState(false)
  const completeAfterMs = useMemo(() => Math.random() * 1500 + 4000, [])
  
  const limits = getCurrentLimits()
  
  return <div className="row">
    <div className="col-12">
      <h2>Ready to Submit!</h2>
      <div>
        <p>This incident qualifies for submitting to CHM because the pre-discount amounts are above {formatCurrency(limits.qualifying_amount)}.</p>
        {lastSubmission?.submission_type === 'HRA' &&
          <p>You've previously received a check from your HRA for some of these expenses.
            After submitting to CHM, you may need to write a reimbursement check to your HRA.</p>}
            
        {!beginning && <button className="btn btn-primary"
          onClick={() => { setBeginning(true) }}>Prepare My Submission</button>}
          
        {beginning &&
          <FakeProgressBar
            steps={[
              'Preparing forms...',
              'Collecting itemized bills...',
              'Scanning for errors...',
            ]}
              completeAfterMs={completeAfterMs}
              onComplete={() => {
                setTimeout(() => {
                  onBegin()
                }, 400)
              }} />}
      </div>
    </div>
  </div>
}

function PendingCHMAddOnSubmission({submission, lastSubmission, onBegin}: PendingSubmissionProps) {
  const [beginning, setBeginning] = useState(false)
  const completeAfterMs = useMemo(() => Math.random() * 1500 + 3000, [])

  return <div className="row">
    <div className="col-12">
      <h2>Ready to Submit!</h2>
      <div>
        <p>
        You've previously submitted this incident to CHM.  You can generate an add-on worksheet to submit
        additional expenses.
        </p>
        {!beginning && <button className="btn btn-primary"
          onClick={() => { setBeginning(true) }}>Prepare my add-on documents</button>}
          
        {beginning &&
          <FakeProgressBar
            steps={[
              'Preparing forms...',
              'Collecting itemized bills...',
              'Scanning for errors...',
            ]}
              completeAfterMs={completeAfterMs}
              onComplete={() => {
                setTimeout(() => {
                  onBegin()
                }, 400)
              }} />}
      </div>
    </div>
  </div>
}

function PendingHRASubmission({submission, lastSubmission, onBegin}: PendingSubmissionProps) {
  const pendingExpenses = useAppSelector(selectPendingExpenses(submission))
  
  const hraCustomizations = useCustomization('hra')
  const canChoosePaidWithHra = hraCustomizations?.providesHraCard
  
  const limits = getCurrentLimits()
  
  const allPendingExpensesHaveSelection = pendingExpenses.every((e) => typeof e.paid_with_hra == 'boolean')
  
  const [expensesConfirmed, setExpensesConfirmed] = useState(
    canChoosePaidWithHra ?
      allPendingExpensesHaveSelection : // If the feature is enabled, then we still need to confirm the expenses (false) if any haven't already been confirmed
      true    // If the feature is not enabled, we don't need to confirm the expenses (true)
  )

  if (!expensesConfirmed) {
    return <HRAPaidWithStatusChecklist submission={submission} onConfirmed={() => setExpensesConfirmed(true)} />
  }
  
  if (!pendingExpenses.length) {
    return <div className="row">
      <h2>Nothing to do.</h2>
      <p>
        All of your expenses have been automatically submitted because you used your HRA card.
      </p>
    </div>
  }

  return <div className="row">
    <div className="col-12">
      <h2>Ready to Submit!</h2>
      <div>
        <p>
        This incident <b>does not</b> qualify for submitting to CHM because the total pre-discount expenses are less than {formatCurrency(limits.qualifying_amount)}.
        </p>
        <p>
        However you can submit it to your HRA.
        </p>
        <button className="btn btn-primary"
          onClick={onBegin}>Submit to HRA</button>
      </div>
    </div>
  </div>
}

/**
 * Looks at all expenses and asks the user to confirm whether they were paid with the HRA or personal funds.
 */
function HRAPaidWithStatusChecklist({submission, onConfirmed}: {submission: PendingSubmissionUnion, onConfirmed: () => void}) {
  const {hraCardLabel} = useCustomization('hra') || {}
  // The normal selectPendingExpenses function excludes expenses that have been paid with HRA, but we want to show those here.
  const pendingExpenses = useAppSelector((s) => {
    return s.expenses.expenses
      .filter((e) => e.incident_id === submission.incident_id)
      .filter((e) => !isSubmittedExpense(e))
  })
  
  const [currentExpenseState, setCurrentExpenseState] = useState(pendingExpenses)
  const changeExpensePaidWith = useCallback((expenseId: string, paid_with_hra: boolean) => {
    const now = new Date().toISOString()
    const expenseIndex = currentExpenseState.findIndex((e2) => e2.id === expenseId)
    const expense = currentExpenseState[expenseIndex]
    if (expense.paid_with_hra === paid_with_hra) { return }
    
    const newExpense = {
      ...expense,
      updated_at: now,
      paid_with_hra,
    }
    const newExpenseState = [...currentExpenseState]
    newExpenseState[expenseIndex] = newExpense
    setCurrentExpenseState(newExpenseState)
  }, [currentExpenseState])
  
  
  const dispatch = useAppDispatch()
  const confirm = useCallback(() => {
    currentExpenseState.forEach((expense, i) => {
      if (pendingExpenses[i].paid_with_hra === expense.paid_with_hra) { return }
      if (pendingExpenses[i].updated_at >= expense.updated_at) { return }

      dispatch(updateExpense(expense))
    })
    
    onConfirmed()
  }, [dispatch, currentExpenseState, pendingExpenses, onConfirmed])

  const anyExpensesMissingPaidWithStatus = currentExpenseState.some((e) => e.paid_with_hra === null || typeof e.paid_with_hra === 'undefined')

  return <div className="row">
    <div className="col-12">
      <h2>Doublecheck These Expenses</h2>
      <div>
        Please look over the following expenses and check whether they were paid with your HRA card or personal funds.
        
        <ul className={`checklist pending-hra-submission__checklist`}>
          {currentExpenseState.map((expense) => {
            const {id, date, paidAmount, provider, paid_with_hra} = expense
            const paidWithHraHasStatus = typeof paid_with_hra !== 'undefined' && paid_with_hra !== null
            
            return <li key={id}
                className={`checklist__item pending-hra-submission__checklist-item ${paidWithHraHasStatus && 'completed'}`}>
              <div className="pending-hra-submission__checklist-item__description">
                {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
                &nbsp;-&nbsp;
                {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
                <span className="d-none d-md-inline">
                  &nbsp;-&nbsp;
                  {provider}
                </span>
              </div>
              
              <div className='pending-hra-submission__checklist-item__form-check-wrapper'>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio'
                    name={`${id}_paid_with`} id={`${id}_paid_with_hra`} value='hra'
                    checked={paid_with_hra === true}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        changeExpensePaidWith(expense.id, true)
                      }
                    }}></input>
                  <label className="form-check-label" htmlFor={`${id}_paid_with_hra`}>{hraCardLabel || 'HRA card'}</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input className='form-check-input' type='radio'
                    name={`${id}_paid_with`} id={`${id}_paid_with_personal`} value='personal'
                    checked={paid_with_hra === false}
                    onChange={(evt) => {
                      if (evt.target.checked) {
                        changeExpensePaidWith(expense.id, false)
                      }
                    }}></input>
                  <label className="form-check-label" htmlFor={`${id}_paid_with_personal`}>Personal</label>
                </div>
              </div>
            </li>
          })}
        </ul>
        
        <Tooltip tooltip={anyExpensesMissingPaidWithStatus &&
            'You must indicate for each expense whether it was paid with the HRA or personal funds.'}>
          <button className={`btn btn-primary ${anyExpensesMissingPaidWithStatus && 'disabled'}`}
            onClick={() => {
              if (!anyExpensesMissingPaidWithStatus) {
                confirm()
              }
            }}>Next</button>
        </Tooltip>
      </div>
    </div>
  </div> 
}
