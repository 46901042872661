import { present } from "../../../lib/util/present";
import { RootState } from "../store";
import { RealizedTodoModel } from "../todosSlice";
import { selectTodosForIncident, selectTodosForExpense, selectTodosForSubmission, selectTodosForAdvance, TodosOptions } from "./todos";

/**
 * Given an incident ID, returns a list of all todos that reference that incident either directly or indirectly
 * through an expense or submission.
 * 
 * This is useful for determining if an incident has any todos that are not yet complete.
 */
export function selectAllTodosReferencingIncident(incidentId: string, options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  return (s: RootState) => {
    const expenseIds = s.expenses.expenses.filter((e) => e.incident_id === incidentId).map((e) => e.id)
    const submissionIds = s.submissions.submissions.filter((s) => s.incident_id === incidentId).map((s) => s.id)
    const advanceIds = s.advances.advances.filter((a) => a.incident_id === incidentId).map((a) => a.id)
    
    return [
      ...selectTodosForIncident(incidentId, options)(s),
      ...expenseIds.flatMap((expenseId) => selectTodosForExpense(expenseId, options)(s)),
      ...submissionIds.flatMap((submissionId) => selectTodosForSubmission(submissionId, options)(s)),
      ...advanceIds.flatMap((advanceId) => selectTodosForAdvance(advanceId, options)(s)),
    ].filter(present)
  }
}
