import type { AppAction } from "../../app/reduxToolkit/store"
import { Json } from "../../types/generated/public"

/**
 * Represents a ToDo that has not been persisted to the database.  These ToDos
 * are calculated based on the current app state.
 * 
 * VirtualTodos are always created in reference to a specific record, therefore they
 * always have the record_id and record_type fields.
 * 
 * Once the underlying state that caused the Todo is remedied, the VirtualTodo will no longer be created by the
 * createToDosFor* functions.  This will indicate that any associated RealizedTodo should be completed.
 */
export interface VirtualToDo {
  key: string
  title: string

  record_id: string
  record_type: RecordsThatHaveTodos
  todo_type: string

  /** Whether this TODO should show a due date, or false if we lack the ability to determine a due date. */
  has_due_date: boolean
  /**
   * The due date for this TODO.
   *  If null and hasDueDate is true, the due date is ASAP.
   */
  due_date?: string | null

  /**
   * The list of todos that this todo depends on.  This one can't be completed until all
   * of the todos in this list are completed.
   */
  depends_on?: string[]

  /**
   * A virtual TODO must always have an action.  If it did not have an action, the user could
   * mark the RealizedTodo as complete, but the underlying state that caused the todo to be created
   * may not be remedied.  Therefore, we require that all virtual todos have an action that guides
   * the user to the correct thing to do to remedy the state.
   */
  action: TodoAction
}

export const RecordTypesThatHaveTodos = [
  'expense',
  'incident',
  'submission',
  'advance'
] as const

export type RecordsThatHaveTodos = typeof RecordTypesThatHaveTodos[number]

export function raiseUnknownRecordType(recordType: never): never {
  throw new Error(`Record types cannot have TODOs: ${recordType}`)
}

export type TodoConfirm = string | {
  title: string,
  body?: string | null | undefined
}

export type TodoAction = {
  type: 'dispatch',
  confirm?: TodoConfirm,
  action: {
    type: string,
    payload: Json
  }
} | {
  type: 'navigate',
  confirm?: TodoConfirm,
  route: string
  state?: any
}

// Type assertions
// Ensure that TodoAction can be serialized to JSON
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertTodoActionSerializable: Json = {} as TodoAction

export function raiseUnknownTodoAction(action: never): never {
  throw new Error(`Unknown ToDo action ${(action as any)?.type || action}`)
}
