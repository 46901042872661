import { AppSupabaseClient, SettingsRow } from "../../types/supabase"
import * as Sentry from '@sentry/react'

export async function getOrCreateSettingsForUser(
  client: AppSupabaseClient,
  params: {
    userId: string
  }
): Promise<SettingsRow> {
  const { userId } = params

  // Try upsert first
  const resp = await client
    .from('settings')
    .upsert(
      { user_id: userId },
      { onConflict: 'user_id' }
    )
    .select()
    .single()

  // If successful, return the result
  if (resp.error) {
    Sentry.captureException(resp.error)
  }
  if (resp.data) {
    return resp.data
  }

  // If upsert didn't return data, fetch the existing record
  const { data, error } = await client
    .from('settings')
    .select()
    .eq('user_id', userId)
    .single()

  if (error) throw error
  if (!data) throw new Error('Failed to get or create settings')
  
  return data
}
