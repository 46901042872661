import { Middleware } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { reconcileTodos } from "../../../lib/todos/reconcileTodos"
import { isTodosSliceAction, updateTodo } from "../todosSlice"
import { selectVirtualToDos } from "../selectors/virtualTodos"
import * as Sentry from '@sentry/react'
import { redactAction } from "../redactAction"

/**
 * This middleware reconciles the virtual and realized todos after any todo-related state changes.
 * It calls reconcileTodos and dispatches the resulting actions.
 */

// Track updates per todo within a time window
const UPDATE_THRESHOLD = 10 // Max updates allowed per todo
const TIME_WINDOW_MS = 5000 // 5 second window
const updateTracker = new Map<string, Array<number>>()

export const todoReconciliationMiddleware: Middleware<{}, RootState> = (store) => (next) => (action) => {
  // Let the action go through first
  const result = next(action)

  // Only run reconciliation if this was not a TODO action
  if (isTodosSliceAction(action)) {
    return result
  }

  let state = store.getState()
  const membershipId = state.membership.membershipId
  if (!membershipId) {
    return result
  }

  const now = new Date()

  // Continue with regular reconciliation
  const virtualTodos = selectVirtualToDos(state)
  const realizedTodos = state.todos.todos

  const reconciliationActions = reconcileTodos(
    virtualTodos,
    realizedTodos,
    action,
    {
      membershipId,
      now
    }
  )

  // Check for potential infinite loops before dispatching
  reconciliationActions.forEach(action => {
    if (action.type === updateTodo.type) {
      const todoId = action.payload.id
      const timestamps = updateTracker.get(todoId) || []
      const currentTime = Date.now()
      
      // Remove timestamps outside the window
      while (timestamps.length && timestamps[0] < currentTime - TIME_WINDOW_MS) {
        timestamps.shift()
      }
      
      timestamps.push(currentTime)
      updateTracker.set(todoId, timestamps)

      if (timestamps.length >= UPDATE_THRESHOLD) {
        const error = new Error(
          `Potential infinite loop detected: Todo ${todoId} updated ${timestamps.length} times in ${TIME_WINDOW_MS}ms`
        )
        Sentry.captureException(error, {
          extra: {
            todoId,
            timestamps,
            lastAction: redactAction(action)
          }
        })
      }
    }

    store.dispatch(action)
  })

  // Cleanup old entries from updateTracker
  const cleanupTime = Date.now() - TIME_WINDOW_MS
  for (const [todoId, timestamps] of updateTracker.entries()) {
    if (timestamps[timestamps.length - 1] < cleanupTime) {
      updateTracker.delete(todoId)
    }
  }

  return result
} 