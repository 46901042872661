import Decimal from "decimal.js"

const stripCommas = (value: string) => value.replace(/,/g, '')

export function tryParseDecimal(string: string | null | undefined): Decimal | null {
  if (!string) { return null }
  try {
    return new Decimal(stripCommas(string))
  } catch (e) {
    return null
  }
}
