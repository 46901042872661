import { useAppSelector } from "../hooks/reduxToolkit"
import { useMemo } from "react"
import { formatDateInTimeZone, parseDateInTimeZone } from "../../lib/formatDateInTimeZone"
import { differenceInDays, endOfYear, startOfDay } from "date-fns"
import { present } from "../../lib/util/present"
import { RealizedTodoModel } from "../reduxToolkit/todosSlice"
import { selectTodosGroupedByIncident } from "../reduxToolkit/selectors/selectTodosGroupedByIncident"
import { byTodoDueDateAscending } from "../../lib/util/sort"
import { useTodoCompletion } from "../hooks/todos/useTodoCompletion"
import { useLocation } from "react-router-dom"

import './todos.scss'

export function Todos() {
  const completedSince = useMemo(() => startOfDay(new Date(Date.now())), [])

  const todos = useAppSelector(selectTodosGroupedByIncident({ completed_since: completedSince }))
  const [completeTodo, { currentAction }, modal] = useTodoCompletion()
  
  // sort the incident IDs by the earliest TODO due date
  const incidentIds = Object.keys(todos.todosByIncident)
  const incidents = useAppSelector((s) => s.incidents.incidents.filter((i) => incidentIds.includes(i.id)))
  const sortedIncidents = incidents.sort((a, b) => {
    const aIncidentTodos = todos.todosByIncident[a.id]
    const bIncidentTodos = todos.todosByIncident[b.id]
    
    const aEarliestTodo = aIncidentTodos.sort(byTodoDueDateAscending)[0]
    const bEarliestTodo = bIncidentTodos.sort(byTodoDueDateAscending)[0]
    
    return byTodoDueDateAscending(aEarliestTodo, bEarliestTodo)
  })
  
  const allTodosAreComplete = Object.values(todos.todosByIncident).every((todos) => todos.every((todo) => present(todo.completed_at))) &&
    todos.remainingTodos.every((todo) => present(todo.completed_at))

  return <div className="row todos">
    <div className="col-12">
      <h1 className="todos__header">Checklist</h1>
      
      {allTodosAreComplete &&
        <p>
          You've taken of everything! Great job!
        </p>}
        
      {sortedIncidents.map((incident) => {
        return <IncidentTodoList key={incident.id} incidentId={incident.id} header={incident.description} todos={todos.todosByIncident[incident.id]} completeTodo={completeTodo} />
      })}

      {todos.remainingTodos.map((todo) => {
        return <TodoItemRow key={todo.id} todo={todo} onClick={() => completeTodo(todo)} />
      })}
    </div>

    {modal}
  </div>
}

function IncidentTodoList({ incidentId, header, todos, completeTodo }: { incidentId: string, header: string, todos: RealizedTodoModel[], completeTodo: (todo: RealizedTodoModel) => void }) {
  const allTodosAreComplete = todos.every((todo) => present(todo.completed_at))

  return <div className={`row todos__incident-list ${allTodosAreComplete ? 'todos__incident-list--complete' : ''}`}>
    <div className="todos__incident-list-header d-flex">
      <div className="col-1 todos__incident-list-expander">
        <button className="btn btn-link" type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#incident-todos-${incidentId}`}
              aria-expanded="true"
              aria-controls={`incident-todos-${incidentId}`}>
          <i className="material-icons"></i>
        </button>
      </div>

      <div className="col-10 todos__incident-list-header-text">
        {header}
      </div>

      <div className="col-1">
        &nbsp;
      </div>
    </div>

    <div className="todos__incident-list-todos collapse show" id={`incident-todos-${incidentId}`}>
      {todos.map((todo) => <TodoItemRow key={todo.id} todo={todo} onClick={() => completeTodo(todo)} />)}
    </div>
  </div>
}

function TodoItemRow({ todo, onClick }: { todo: RealizedTodoModel, onClick: () => void }) {
  const highlightTodoId = useLocation().state?.highlightTodoId

  const today = useMemo(() => startOfDay(new Date(Date.now())), [])
  const eoy = useMemo(() => endOfYear(new Date(Date.now())), [])
  const hasDueDate: boolean = todo.has_due_date || present(todo.due_date) || false
  let dueDate = todo.due_date && parseDateInTimeZone(todo.due_date)

  const isPast = dueDate && dueDate < today
  const dueSoon = !dueDate || differenceInDays(dueDate, new Date()) <= 14
  const isASAP = hasDueDate && (!dueDate || isPast)

  const isComplete = present(todo.completed_at)

  return <div className={`todos__list-item row ${isComplete ? 'complete' : 'incomplete'} ${highlightTodoId === todo.id ? 'todos__list-item--highlighted' : ''}`}>
      <div className="col-1 todos__list-item__expander">
        &nbsp;
      </div>

      <div className="col-8 todos__list-item__content"
          onClick={onClick}>
        <span className="todos__list-item__text">{todo.title}</span>
      </div>

      <div className="col-3 todos__list-item__actions">
          {(!isComplete && hasDueDate) &&
            <span className={`todos__list-item__due-date badge ${dueSoon ? 'bg-danger' : 'bg-secondary '}`}>
              <span className="d-none d-md-inline">due&nbsp;</span>{
              (isASAP) ?
                'ASAP' :
                (present(dueDate)) ?
                  formatDateInTimeZone(dueDate, { format: dueDate && dueDate > eoy ? 'MMM dd, yyyy' : 'MMM dd' }) :
                  'unknown'
              }
            </span>}
          
          {(isComplete) &&
            <span className={`todos__list-item__due-date badge bg-success`}>
              <span className="d-none d-md-inline">completed&nbsp;</span>{
              (present(todo.completed_at)) ?
                formatDateInTimeZone(todo.completed_at, { format: 'MMM dd' }) :
                ''
              }
            </span>}

          {!isComplete &&
            <i className="material-icons d-none d-md-inline-block">check_box_outline_blank</i>}
        </div>
    </div>
}
