import path from 'path-browserify'
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {OnboardingFillProfile} from './onboarding/fillProfile';
import { useEffect, useState } from 'react';
import formatISO from 'date-fns/formatISO';
import { useClient } from '../providers/supabase';
import uniq from 'lodash/uniq';
import { ProfileRow } from '../../types/supabase';
import { OnboardingSetMembershipDetails } from './onboarding/setMembershipDetails';
import { useUser } from "../hooks/useUser";
import { OnlineRequired } from './onlineRequired';
import { PostgrestError } from '@supabase/supabase-js';
import { SignOutButton } from '../components/signOutButton';
import { Help } from '../routes/help';
import { NotFound } from './notFound';
import { useMailchimpSignUpHandler } from '../hooks/useMailchimpSignUpHandler';
import AlertBanner from '../components/alertBanner';
import { OnboardingFlowState, OnboardingFlowStates, hasCompletedOnboarding } from '../reduxToolkit/membershipSlice';
import { OnboardingHome } from './onboarding/onboardingHome';
import { OnboardingRedeemCode } from './onboarding/redeemCode';
import { OnboardingStartTour } from './onboarding/startTour';

import './onboarding.scss'

function Onboarding({
  onComplete
} : {
  onComplete: () => void
}) {
  const client = useClient()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const [{}, mailchimpSignUp] = useMailchimpSignUpHandler()
  
  const pathname = path.basename(location.pathname)
  const currentIdx = OnboardingFlowStates.findIndex((p) => p === pathname)

  const [stepsCompleted, setStepsCompleted] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error>()
  const [wait, setWait] = useState(false)

  useEffect(() => {
    if (!user?.id) { throw new Error(`User is not signed in`) }

    client.from('profiles')
      .select('onboarding_steps_completed')
      .eq('id', user.id)
      .then(
        ({ data, error }) => {
          if (error) { setError(new Error(error.message, { cause: error })) }
          if (data && data.length > 0) {
            const profile = data[0] as ProfileRow
            setStepsCompleted(profile.onboarding_steps_completed || [])
          }
          setLoading(false)
        },
        (error) => {
          setError(error)
          setLoading(false)
        }
      )
  }, [client, user?.id])

  if (error) {
    if (/NetworkError/.test(error.message)) {
      return <OnlineRequired onConnectionEstablished={() => {
        setError(undefined)
      }} />
    }
    // else
    throw error
  }

  if (!user) {
    throw new Error(`User is not signed in`)
  }

  if (loading) {
    return <div className="row">
      <div className="col-12 col-lg-6 offset-lg-3">
        <div className={`loading-bar`}></div>
      </div>
    </div>
  }

  const onStepComplete = (step: OnboardingFlowState) => {
    if (!user?.id) { throw new Error('Please sign in first') }

    setWait(true)
    const newStepsCompleted = uniq([...(stepsCompleted || []), step])
    setStepsCompleted(newStepsCompleted)

    const completeStep = async () => {
      const { error } = await client.from('profiles').update({
          onboarding_step_completed_at: formatISO(new Date()),
          onboarding_steps_completed: newStepsCompleted
        })
        .eq('id', user.id)
      if (error) {
        throw new Error('Failed to complete onboarding step', {
          cause: error,
        });
      }

      if ((currentIdx + 1) >= OnboardingFlowStates.length) {
        console.log('onboarding complete')
        navigate('/')
        mailchimpSignUp(['onboarding-complete'])
        onComplete()
      } else {
        const nextPath = OnboardingFlowStates[currentIdx + 1]
        navigate(nextPath)
      }
    }

    return completeStep()
      .catch((err) => {
        setError(err)
      })
      .finally(() => setWait(false))
  }

  return <div className='App container'>
    <div className="row" style={{ height: '100vh', flexDirection: 'column' }}>
      <div className='col-12'>
        <OnboardingTopNav />
        <AlertBanner />
      </div>

      <div className="col-12">
        <Routes>
          <Route index element={<OnboardingHome hasCompletedOnboarding={hasCompletedOnboarding({ onboarding_steps_completed: stepsCompleted })} />} />
          <Route path="redeemCode" element={<OnboardingRedeemCode />} />
          <Route path="setMembership" element={<OnboardingSetMembershipDetails user={user} onComplete={() => onStepComplete('setMembership')} />} />
          <Route path="fillProfile" element={<OnboardingFillProfile user={user} onProfileFilled={() => onStepComplete('fillProfile')} />} />
          <Route path="startTour" element={<OnboardingStartTour onComplete={() => onStepComplete('startTour')} />} />

          <Route path="/help" element={<Help />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <div className={`loading-bar ${wait ? 'visible' : 'invisible'}`}></div>
      </div>

      <div className="col-12" style={{marginTop: 'auto'}}>
        <OnboardingFlowProgress currentIdx={currentIdx} />
      </div>
    </div>
  </div>
}

export default Onboarding


const titles: Record<OnboardingFlowState, string> = {
  setMembership: 'Set Membership Details',
  fillProfile: 'Fill out your Profile',
  startTour: 'Start Tour',
}

function OnboardingFlowProgress({ currentIdx }: { currentIdx: number}) {
  const [last, current, next] = [
    OnboardingFlowStates[currentIdx - 1],
    OnboardingFlowStates[currentIdx],
    OnboardingFlowStates[currentIdx + 1]
  ]

  return <div className="onboarding__flow-state row">
    {last ?
      <div className="onboarding__flow-state__step col-4 last">
        {titles[last] || last}
      </div> :
      <div className="col-4" />}

    {current ?
      <div className="onboarding__flow-state__step col-4 current">
        {titles[current] || current}
      </div> :
      <div className="col-4" />}

    {next ?
      <div className="onboarding__flow-state__step col-4 next">
        Next: {titles[next] || next}
      </div> :
      <div className="col-4" />}
  </div>
}

function OnboardingTopNav() {
  return <nav className="navbar navbar-expand-lg top-nav">
    <div className="container-fluid d-none d-md-flex">
      <div className='navbar-brand'>
        <h2>HealthShare</h2>
      </div>

      <div className='nav-item'>
        <Link to='/' className='nav-link'>Onboarding</Link>
      </div>


      <div className='nav-item mt-auto'>
        <SignOutButton className='nav-link'>Sign Out</SignOutButton>
      </div>

      <li className='nav-item'>
        <Link to="help" className='nav-link'>
          <i className="material-icons d-none d-md-inline-block">help</i>
          <span className='d-none d-md-inline'>Help</span>
        </Link>
      </li>
    </div>


    <div className="container-fluid d-flex d-md-none">
      <div className='nav-item'>
        <Link to='/' className='nav-link'>
          <i className='material-icons'>home</i>
        </Link>
      </div>

      <div className='nav-item'>
        <Link to="help" className='nav-link'>
          <i className="material-icons">help</i>
        </Link>
      </div>

      <div className='nav-item'>
        <SignOutButton className='nav-link'>
          <i className='material-icons'>logout</i>
        </SignOutButton>
      </div>
    </div>
</nav>
}

