import { useCallback, useState } from "react"
import usePortal from "react-useportal"
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router";
import { ConfirmDispatchAction } from "../../components/todos/confirmDispatchAction";
import { ConfirmNavigateAction } from "../../components/todos/confirmNavigateAction";
import { TodoAction, raiseUnknownTodoAction } from "../../../lib/todos/types";
import { useAppDispatch } from "../reduxToolkit";
import { completeTodo, RealizedTodoModel } from "../../reduxToolkit/todosSlice";

/**
 * Creates an onClick handler for a ToDo list item that will perform the action associated with the todo item.
 *
 * The action is performed in a modal dialog that is created and destroyed as needed.  The modal must be mounted
 * by the caller.
 * @returns [onClick, { currentAction }, modal]
 * @example
 *  const [onClick, { currentAction }, modal] = useTodoCompletion()
 *
 *  return <div className="todo-list">
 *    {incompleteTodos.map((todo) => <ToDoListItem key={todo.key} onClick={() => onClick(todo)} />)}
 *    {modal}
 *  </div>
 */
export function useTodoCompletion() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
    
  const [currentTodo, setCurrentTodo] = useState<RealizedTodoModel | null>(null)

  const { Portal } = usePortal()
  const [uniqueId, setUniqueId] = useState<number>(Math.random())
  const domId = `modal-todo-list-${uniqueId}`

  const onClick = useCallback((todo: RealizedTodoModel) => {
    const currentAction: TodoAction | null | undefined = todo.action

    if (currentAction?.type === 'dispatch') {
      if (!currentAction.confirm) {
        // Immediately dispatch w/o confirmation
        dispatch(currentAction.action)
        // and mark the todo as complete
        onCompleted()
        return
      }
    }
    if (currentAction?.type === 'navigate') {
      if (!currentAction.confirm) {
        // Immediately dispatch w/o confirmation
        navigate(currentAction.route, { state: currentAction.state })
        // and mark the todo as complete
        onCompleted()
        return
      }
    }

    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById(domId)!)
    modal?.show()
    setCurrentTodo(todo)
  }, [dispatch, domId, navigate])

  const onCompleted = useCallback(() => {
    const modal = bootstrap.Modal.getInstance(document.getElementById(domId)!)
    modal?.hide()
    setUniqueId(Math.random())
    
    if (currentTodo) {
      const now = new Date().toISOString()
      dispatch(completeTodo({
        id: currentTodo.id,
        updated_at: now,
        completed_at: now
      }))
    }

    setCurrentTodo(null)
  }, [domId, currentTodo, dispatch])

  const onCancelled = useCallback(() => {
    const modal = bootstrap.Modal.getInstance(document.getElementById(domId)!)
    modal?.hide()
    setUniqueId(Math.random())
    setCurrentTodo(null)
  }, [domId])

  let title: string | null = null
  let action: JSX.Element | null = null
  if (currentTodo?.action) {
    switch(currentTodo.action.type) {
      case 'dispatch':
        action = <ConfirmDispatchAction confirm={currentTodo.action.confirm} action={currentTodo.action.action} onCompleted={onCompleted} onCancelled={onCancelled} />
        break;
      case 'navigate':
        action = <ConfirmNavigateAction route={currentTodo.action.route} confirm={currentTodo.action.confirm} onCompleted={onCompleted} onCancelled={onCancelled} />
        break;
      default:
        raiseUnknownTodoAction(currentTodo.action)
    }
  } else if (currentTodo) {
    action = <ConfirmCompleteTodo todo={currentTodo} onCompleted={onCompleted} onCancelled={onCancelled} />
  }


  const modal = <Portal>
      <div className="modal modal-lg fade" id={domId} aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="exampleModalLabel">{title}</h2>
              <button type="button" className="btn-close"
                onClick={onCancelled}
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {action}
            </div>
          </div>
        </div>
      </div>
    </Portal>

  return [
    onClick,
    {
      currentAction: currentTodo?.action,
    },
    modal
  ] as const
}

interface ConfirmCompleteTodoProps {
  todo: RealizedTodoModel
  onCompleted: () => void
  onCancelled: () => void
}

function ConfirmCompleteTodo({ todo, onCompleted, onCancelled }: ConfirmCompleteTodoProps) {
  return <div className="row">
    <div className="col">
      <h3 style={{textAlign: 'center'}}>Did you complete this?</h3>
      <p>
        This will mark the following checklist item as complete and remove it from the list:
      </p>
      <h5><i>{todo.title}</i></h5>
      <div className="w-100 d-flex justify-content-center">
        <div className="btn-group btn-group-lg">
          <button type="button" className="btn btn-primary"
            onClick={onCompleted}>
              Yes
          </button>
          <button type="button" className="btn btn-outline-secondary"
            onClick={onCancelled}>
              No
          </button>
        </div>
      </div>
    </div>
  </div>
}
