import { present } from "./present"

export function byCreatedAtDescending(a: { created_at: string }, b: { created_at: string }) {
  return Date.parse(b.created_at) - Date.parse(a.created_at)
}

export function byStartDateDescending(a: { start_date: string }, b: { start_date: string }) {
  return Date.parse(b.start_date) - Date.parse(a.start_date)
}

export function byStartDateAscending(a: { start_date: string }, b: { start_date: string }) {
  return Date.parse(a.start_date) - Date.parse(b.start_date) 
}

export function byStartDateAscendingNullsFirst(a: { start_date?: string | null | undefined }, b: { start_date?: string | null | undefined }) {
  if (!a.start_date && !b.start_date) {
    return 0
  }
  if (!a.start_date) {
    return -1
  }
  if (!b.start_date) {
    return 1
  }
  return Date.parse(a.start_date) - Date.parse(b.start_date) 
}

export function bySubmittedAtDesc(a: { submitted_at: string }, b: { submitted_at: string }) {
  return Date.parse(a.submitted_at) - Date.parse(b.submitted_at)
}

export function bySubmittedAtDescNullsFirst(a: { submitted_at?: string | null }, b: { submitted_at?: string| null }) {
  // nulls are "less than" non-nulls
  if (!a.submitted_at) { return 1 }
  if (!b.submitted_at) { return -1 }

  // invert ascending to get descending
  return -1 * a.submitted_at.localeCompare(b.submitted_at)
}

export function bySubmittedAtAsc(a: { submitted_at: string }, b: { submitted_at: string }) {
  return a.submitted_at.localeCompare(b.submitted_at)
}

export function bySubmittedAtAscNullsLast(a: { submitted_at?: string | null }, b: { submitted_at?: string| null }) {
  // nulls are "greater than" non-nulls
  if (!a.submitted_at) { return -1 }
  if (!b.submitted_at) { return 1 }

  return a.submitted_at.localeCompare(b.submitted_at)
}

export function byUpdatedAtDescending(a: { updated_at: string }, b: { updated_at: string }) {
  return Date.parse(b.updated_at) - Date.parse(a.updated_at)
}

export function byUpdatedAtAscending(a: { updated_at: string }, b: { updated_at: string }) {
  return Date.parse(a.updated_at) - Date.parse(b.updated_at)
}

export function byDueDateAscendingNullsLast(a: { due_date?: string | null | undefined }, b: { due_date?: string | null | undefined }) {
  if (!a.due_date && !b.due_date) {
    return 0
  }
  if (!a.due_date) {
    return 1
  }
  if (!b.due_date) {
    return -1
  }
  return a.due_date.localeCompare(b.due_date)
}

export function byDueDateAscendingNullsFirst(a: { due_date?: string | null | undefined }, b: { due_date?: string | null | undefined }) {
  if (!a.due_date && !b.due_date) {
    return 0
  }
  if (!a.due_date) {
    return -1
  }
  if (!b.due_date) {
    return 1
  }
  return a.due_date.localeCompare(b.due_date)
}

export function byDateAscending(a: { date: string }, b: { date: string }) {
  return Date.parse(a.date) - Date.parse(b.date)
}

/**
 * Sorts todos by "importance".
 * * Incomplete todos are more important than complete todos.
 * * Todos with due dates are more important than todos without due dates.
 * * Todos with earlier due dates are more important than todos with later due dates.
 */
export function byTodoDueDateAscending(a: {has_due_date: boolean, due_date?: string | null | undefined, completed_at?: string | null | undefined}, b: {has_due_date: boolean, due_date?: string | null | undefined, completed_at?: string | null | undefined}) {
  // If both todos are complete, compare the completion dates
  if (a.completed_at && b.completed_at) {
    return byCompletedAtAscendingNullsLast(a, b)
  }

  // If only one todo is complete, the complete todo should come last
  if (a.completed_at) {
    return 1
  }
  if (b.completed_at) {
    return -1
  }

  // If both todos have due dates, compare the due dates
  if (a.has_due_date && b.has_due_date) {
    // In this case, null means "ASAP"
    return byDueDateAscendingNullsFirst(a, b)
  }

  // If only one todo has a due date, it should come first
  if (a.has_due_date) {
    return -1
  }
  if (b.has_due_date) {
    return 1
  }

  // If neither todo has a due date, return 0
  return 0
}

export function byCompletedAtAscendingNullsLast(a: { completed_at?: string | null | undefined }, b: { completed_at?: string | null | undefined }) {
  if (!a.completed_at) { return 1 }
  if (!b.completed_at) { return -1 }

  return a.completed_at.localeCompare(b.completed_at)
}
