import { AnyAction } from "@reduxjs/toolkit"
import { isAdvancesSliceAction, redactAdvancesSliceAction } from "./advancesSlice"
import { isAttachmentsSliceAction, redactAttachmentsSliceAction } from "./attachmentsSlice"
import { isExpensesSliceAction, redactExpensesSliceAction } from "./expensesSlice"
import { isIncidentsSliceAction, redactIncidentsSliceAction } from "./incidentsSlice"
import { isNotificationsSliceAction, redactNotificationsSliceAction } from "./notificationsSlice"
import { isProvidersSliceAction, redactProvidersSliceAction } from "./providersSlice"
import { isSubmissionsSliceAction, redactSubmissionsSliceAction } from "./submissionsSlice"
import { isSyncSliceAction, redactSyncSliceAction } from "./syncSlice"
import { isTodosSliceAction, redactTodosSliceAction } from "./todosSlice"

export function redactAction(action: AnyAction) {
  if (isExpensesSliceAction(action)) {
    return redactExpensesSliceAction(action)
  } else if (isAttachmentsSliceAction(action)) {
    return redactAttachmentsSliceAction(action)
  } else if (isIncidentsSliceAction(action)) {
    return redactIncidentsSliceAction(action)
  } else if (isSubmissionsSliceAction(action)) {
    return redactSubmissionsSliceAction(action)
  } else if (isSyncSliceAction(action)) {
    return redactSyncSliceAction(action)
  } else if (isAdvancesSliceAction(action)) {
    return redactAdvancesSliceAction(action)
  } else if (isNotificationsSliceAction(action)) {
    return redactNotificationsSliceAction(action)
  } else if (isProvidersSliceAction(action)) {
    return redactProvidersSliceAction(action)
  } else if (isTodosSliceAction(action)) {
    return redactTodosSliceAction(action)
  }

  // otherwise redact the payload
  return {
    type: action.type,
    payload: 'REDACTED'
  }
}