import { RootState } from "../store"
import { RealizedTodoModel } from "../todosSlice"
import { selectAllTodosReferencingIncident } from "./selectAllTodosReferencingIncident"
import { selectTodos, TodosOptions } from "./todos"

type ReturnValue = {
  todosByIncident: Record<string, RealizedTodoModel[]>
  remainingTodos: RealizedTodoModel[]
}

export function selectTodosGroupedByIncident(options: TodosOptions = {}): (s: RootState) => ReturnValue {
  return (s: RootState) => {
    const returnValue: ReturnValue = {
      todosByIncident: {},
      remainingTodos: []
    }
    const seenTodos = new Set<string>()
    for (const incident of s.incidents.incidents) {
      const todos = selectAllTodosReferencingIncident(incident.id, options)(s)
      for (const todo of todos) {
        returnValue.todosByIncident[incident.id] = [...(returnValue.todosByIncident[incident.id] || []), todo]
        seenTodos.add(todo.id)
      }
    }
    
    for (const todo of selectTodos(options)(s)) {
      if (!seenTodos.has(todo.id)) {
        returnValue.remainingTodos.push(todo)
        seenTodos.add(todo.id)
      }
    }
      
    return returnValue
  }
}
