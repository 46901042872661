import { AppSupabaseClient, MembershipRow, MembershipRoleRow } from "../../types/supabase"
import { present } from "../util/present"

export async function updateMemberNumber(
  client: AppSupabaseClient,
  params: {
    membershipId: string,
    memberNumber: string,
  }
): Promise<{ member_number: string }> {
  const { membershipId, memberNumber } = params
  const membershipUpdate = await client.from('memberships')
    .update({
      member_number: memberNumber
    })
    .eq('id', membershipId)
    .select('member_number')

  if (membershipUpdate.error) {
    if (/violates unique constraint/.test(membershipUpdate.error.message)) {
      throw new Error(`Member number ${memberNumber} is already in use - please contact support if you believe this is an error.`)
    }
    throw new Error(`Unable to update member number for membership ${membershipId}: ${membershipUpdate.error.message}`)
  }
    
  const member_number = membershipUpdate.data?.[0]?.member_number
  if (!present(member_number)) {
    throw new Error(`Unable to update member number for membership ${membershipId}`)
  }

  return {
    member_number
  }
}
