import { Link, useNavigate } from "react-router-dom"
import { v4 as uuid4 } from "uuid";
import { useAppDispatch } from "../../../hooks/reduxToolkit"
import { useCallback } from "react"
import { useUser } from "../../../hooks/useUser"
import { RequireSubmissionProvided, requireSubmission } from "../../../wrappers/requireSubmission"
import { addSubmission } from "../../../reduxToolkit/submissionsSlice"
import { Tooltip } from "../../../components/tooltip"
import { Checklist } from "../../../components/checklist";
import { useCustomization } from "../../../hooks/useCustomizations";

interface SubmitPersonalResponsibilityProps extends RequireSubmissionProvided {
}

export function SubmitPersonalResponsibility({submission}: SubmitPersonalResponsibilityProps) {
  const dispatch = useAppDispatch()
  const user = useUser()
  const navigate = useNavigate()
  const personalResponsibility = useCustomization('personalResponsibility')

  const incident = submission.incident

  const finishSubmission = useCallback(() => {
    // Add a new submission to represent the personal responsibility submission
    const now = new Date().toISOString()
    dispatch(addSubmission({
      id: uuid4(),
      created_at: now,
      updated_at: now,
      membership_id: submission.membership_id,
      parent_submission_id: submission.id,
      incident_id: submission.incident_id,
      submission_type: 'CHM-personal-responsibility',
      submitted_by_user_id: user?.id || '',
      submitted_at: now,
      // Mark these as having been reimbursed
      expense_ids: submission.expense_ids,
    }))
  }, [dispatch, submission, user?.id])

  const cancelSubmission = useCallback(() => {
    const clickedOK = window.confirm('You haven\'t finished. Are you sure you want to cancel?\n' +
      'Click "OK" to stay here and "Cancel" to leave this page.')

    if (!clickedOK) {
      navigate(`/incidents/${incident.id}`)
    }
  }, [incident.id, navigate])
  
  if (!personalResponsibility?.reimbursesChmPersonalResponsibility) {
    throw new Error('This page should not be shown if the HRA does not reimburse CHM personal responsibility')
  }

  return <div className="row">
    <div className="col-12">
      <button type="button" className="btn-close float-end" aria-label="Close"
        onClick={cancelSubmission}></button>
      <h2>Submit Personal Responsibility Letter to HRA</h2>

      <p>
        According to our records, you have not yet met your CHM Personal Responsibility.
        You can expect to receive a letter from CHM showing how much of your claims they will not pay.
      </p>

      <p>
        Your personal responsibility is covered through your HRA.  When you receive your letter from CHM,
        you can submit it to the HRA and you will be reimbursed for that amount.
      </p>


      <p>
        <h4>Next Steps:</h4>
        <Checklist
          items={[
            <span>
              <a href={personalResponsibility.reimbursementUrl} target="_blank" rel="noreferrer">
                Open this link</a> and click "Reimburse Myself".
            </span>,
            <span>
              Choose "Plan 3 Personal Responsibility Plan" in the dropdown
            </span>,
            <span>
              Upload the letter you received from CHM
            </span>,
            <span>
              Submit your claim
            </span>
          ]} />
      </p>

      <p>
        <Link to={`/incidents/${incident.id}`} className={`btn btn-primary`}
          onClick={finishSubmission}>Done</Link>
        <button onClick={(e) => {
          e.preventDefault();
          navigate(-1)
        }} className={`btn btn-link`}>I'll do this later</button>
      </p>
      <p>
        <Link to={`/incidents/${incident.id}`} className={`btn btn-sm btn-link`}
          onClick={finishSubmission}>I have previously fulfilled my personal responsibility</Link>

        <Tooltip
          tooltip="Click this if you have submitted expenses to CHM that you haven't tracked through the app" />
      </p>
    </div>
  </div>
}

export default requireSubmission(SubmitPersonalResponsibility)
