import { selectPendingExpenses } from "../../app/hooks/usePendingExpenses"
import { getOrBuildPendingSubmissionModel } from "../../app/hooks/usePendingSubmission"
import { ExpenseModel, isCompleteExpense } from "../../app/reduxToolkit/expensesSlice"
import { IncidentModel } from "../../app/reduxToolkit/incidentsSlice"
import { RootState } from "../../app/reduxToolkit/store"
import { VirtualToDo } from "./types"
import { present } from "../util/present"
import { calculateDueDate, chooseSubmissionType } from "../rules/chooseSubmissionType"
import { formatCurrency } from "../formatCurrency"
import { Customizations, selectCustomizations } from "../../app/reduxToolkit/selectors/customizations"
import { addDays, addWeeks, formatISO } from "date-fns"
import { markHasCalledCHMForPregnancy } from "../../app/reduxToolkit/incidentsSlice"
import { parseDateInTimeZone } from "../formatDateInTimeZone"
import { Decimal } from "decimal.js"
import { SubmissionModel } from "../../app/reduxToolkit/submissionsSlice"
import { SubmissionType } from "../../types/supabase"

export function * createToDosForIncident(s: RootState, incident: IncidentModel): Generator<VirtualToDo> {
  const customizations = selectCustomizations(s)
  const incidentExpenses = s.expenses.expenses.filter((e) => e.incident_id === incident.id)
  const incidentSubmissions = s.submissions.submissions.filter((s) => s.incident_id === incident.id)
  const submissionType = chooseSubmissionType({
    incident,
    incidentExpenses,
    incidentSubmissions,
  }, customizations)
  
  let incidentDueDate: string | null = null
  if (submissionType) {
    incidentDueDate = calculateDueDate({
      incident,
      incidentExpenses,
      incidentSubmissions,
      submissionType,
    })
  }
  
  let checks: Array<(s: RootState, incident: IncidentModel, context: IncidentTodoContext) => VirtualToDo | void> = [
    checkSubmitTodo,
    checkMaternityCallChmTodo,
    checkMaternityPrepaymentAgreementTodo
  ]
  
  for (const check of checks) {
    const context: IncidentTodoContext = {
      incidentExpenses,
      incidentSubmissions,
      submissionType,
      customizations,
      incidentDueDate,
    }
    const todo = check(s, incident, context)
    if (todo) {
      yield todo
    }
  }
}

type IncidentTodoContext = {
  incidentExpenses: ExpenseModel[],
  incidentSubmissions: SubmissionModel[],
  submissionType: SubmissionType | null,
  customizations: Customizations,
  incidentDueDate: string | null,
}

function checkSubmitTodo(s: RootState, incident: IncidentModel, context: IncidentTodoContext): VirtualToDo | void {
  const key = `incident/${incident.id}/submit`

  // If there are any expenses, this incident needs a TODO.
  if (context.incidentExpenses.length > 0) {
    const pendingSubmission = getOrBuildPendingSubmissionModel(
      {
        ...context,
        incident,
      },
      context.customizations
    )
    if (!pendingSubmission) { return }

    const pendingExpenses = selectPendingExpenses(pendingSubmission)(s)
    const complete = pendingExpenses.length === 0
    if (!complete) {
      const incompleteExpenses = pendingExpenses.filter((e) => !isCompleteExpense(e))
      const total = pendingExpenses.filter(isCompleteExpense).reduce((sum, expense) => {
        if (!expense?.paidAmount) { return sum }
        const amount = new Decimal(expense.paidAmount)
        return sum.plus(amount)
      }, new Decimal(0))

      if (total.gt(0)) {
        return {
          key,
          title: `Submit '${incident.description}' to get ${formatCurrency(total)} reimbursement`,
          due_date: context.incidentDueDate,
          has_due_date: true,
          depends_on: [
            ...incompleteExpenses.map((e) => `expense/${e.id}/fill`),
            ...pendingExpenses.map<string | null>((e) => {
              // do we need "receipt" or "itemized bill"?
              let todoType: string | null = null
              // If the submission is going to CHM or CHM-Addon, we need an itemized bill.
              if (pendingSubmission.submission_type === 'CHM' || pendingSubmission.submission_type === 'CHM-addon') {
                todoType = 'itemized-bill'
              }
              // If the submission is going to the HRA, AND this expense was a personal expense, we need a receipt.
              else if (pendingSubmission.submission_type === 'HRA' && !e.paid_with_hra) {
                todoType = 'receipt'
              }
              // Otherwise we don't need a TODO here.
              else { return null }
              
              return `expense/${e.id}/${todoType}`
            }).filter(present)
          ],
          record_id: incident.id,
          record_type: 'incident',
          todo_type: 'submit',
          action: {
            type: 'navigate',
            route: `/incidents/${incident.id}`
          }
        }
      }
    }
  }
}

function checkMaternityCallChmTodo(s: RootState, incident: IncidentModel, context: IncidentTodoContext): VirtualToDo | void {
  if (!incident.is_maternity) { return }
  if (!incident.maternity_due_date) { return }
  if (incident.maternity_chm_call_completed_at) { return }
  
  let dueDate = addWeeks(parseDateInTimeZone(incident.maternity_due_date), -40 + 16);
  dueDate = addDays(dueDate, -1);
  return {
    key: `incident/${incident.id}/maternity-call-chm`,
    title: `Call CHM for pregnancy support`,
    record_id: incident.id,
    record_type: 'incident',
    todo_type: 'maternity-call-chm',
    has_due_date: true,
    due_date: formatISO(dueDate, { representation: 'date' }),
    action: {
      type: 'dispatch',
      action: markHasCalledCHMForPregnancy({id: incident.id}),
            
      confirm: {
        title: `Did you call CHM to let them know you're pregnant?`,
        body: 'When you do this prior to 16 weeks pregnant, CHM will take $500 off your Personal Responsibility.'
      },
    }
  }
}

/**
 * Instructs the user to call the hospital and get a global bill for the pregnancy.
 * 
 * This should be done after initially calling CHM for maternity support.
 */
function checkMaternityPrepaymentAgreementTodo(s: RootState, incident: IncidentModel, context: IncidentTodoContext): VirtualToDo | void {
  if (!incident.is_maternity) { return }
  if (!incident.maternity_due_date) { return }
  if (!incident.maternity_chm_call_completed_at) { return }
  
  const incidentHasPrepaymentAgreement = s.expenses.expenses.some((e) => e.incident_id === incident.id && e.is_prepayment_agreement)
  if (incidentHasPrepaymentAgreement) { return }
  
  const dueDate = parseDateInTimeZone(incident.maternity_due_date);
  return {
    key: `incident/${incident.id}/maternity-prepayment-agreement`,
    title: `Get a prepayment agreement from the hospital`,
    record_id: incident.id,
    record_type: 'incident',
    todo_type: 'maternity-prepayment-agreement',
    has_due_date: true,
    due_date: formatISO(dueDate, { representation: 'date' }),
    action: {
      type: 'navigate',
      route: `/incidents/${incident.id}`
    }
  }
}
