import { SubmissionType } from "../../types/supabase";
import { ExpenseModel, isCompleteExpense } from "../../app/reduxToolkit/expensesSlice";
import { IncidentModel } from "../../app/reduxToolkit/incidentsSlice";
import { SubmissionModel, isSubmitted } from "../../app/reduxToolkit/submissionsSlice";
import { assert } from "../util/assert";
import { formatISO, addMonths } from "date-fns";
import { parseDateInTimeZone } from "../formatDateInTimeZone";
import { Customizations } from "../../app/reduxToolkit/selectors/customizations";
import { Decimal } from 'decimal.js';
import { getCurrentLimits } from "./limits";
import { present } from "../util/present";

export function chooseSubmissionType(
  {
    incident,
    incidentSubmissions,
    incidentExpenses,
  }: {
    incident: IncidentModel,
    incidentSubmissions: SubmissionModel[],
    incidentExpenses: ExpenseModel[],
  },
  {
    hra
  }: Pick<Customizations, 'hra'>,
): SubmissionType | null {
  const limits = getCurrentLimits()
  const priorSubmissions = incidentSubmissions.filter(isSubmitted)
  
  // If there are no expenses, we can't submit
  if (incidentExpenses.length === 0) {
    return null
  }
  
  // If there are any incomplete expenses, we can't submit yet
  if (incidentExpenses.some((e) => !isCompleteExpense(e))) {
    return null
  }

  const completeExpenses = incidentExpenses.filter(isCompleteExpense)

  // If we have previously submitted to CHM, this is an add-on incident.
  if (incident.is_addon_incident ||
      priorSubmissions.find((s) => s.submission_type === 'CHM')) {
    return 'CHM-addon'
  }

  // If we have previously submitted to an HRA, we need to roll up those expenses because we might re-submit them to CHM.
  if (priorSubmissions.find((s) => s.submission_type === 'HRA')) {
    const sumListed = completeExpenses.reduce((sum, expense) => 
      sum.plus(new Decimal(expense.listedAmount)), new Decimal(0))
    
    if (sumListed.greaterThanOrEqualTo(limits.qualifying_amount)) {
      return 'CHM'
    }

    if (hra?.hasHra) {
      return 'HRA'
    } else {
      return null
    }
  }

  // If we have not previously submitted to an HRA, at this point there should be no prior submissions
  assert(priorSubmissions.length === 0, 'There should be no prior submissions')

  const sumListed = completeExpenses.reduce((sum, expense) => 
    sum.plus(new Decimal(expense.listedAmount)), new Decimal(0))
    
  if (sumListed.greaterThanOrEqualTo(limits.qualifying_amount)) {
    return 'CHM'
  }

  // If the remaining expenses are all paid with the HRA card, we don't need to resubmit them to the HRA
  if (completeExpenses.every((e) => e.paid_with_hra)) {
    return null
  }

  if (hra?.hasHra) {
    return 'HRA'
  } else {
    return null
  }
}

/**
 * Calculates the next CHM submission due date for an incident.
 * This is 6 months from the date of the earliest expense that has not been previously submitted to CHM.
 */
export function calculateDueDate({
  incident,
  incidentExpenses,
  incidentSubmissions,
  submissionType,
}: {
  incident: IncidentModel,
  incidentExpenses: ExpenseModel[],
  incidentSubmissions: SubmissionModel[],
  submissionType: SubmissionType,
}
) {
  const expenseIDsSubmittedToCHM = incidentSubmissions.filter((s) => s.submission_type === 'CHM' || s.submission_type === 'CHM-addon')
    .flatMap((s) => s.expense_ids)
  const expensesNotSubmittedtoCHM = incidentExpenses
    .filter((e) => !expenseIDsSubmittedToCHM.includes(e.id))

  const earliestExpenseDate = expensesNotSubmittedtoCHM.map((e) => e.date).filter(present).sort()[0]
  
  if (!earliestExpenseDate) {
    return null
  }

  // Medical bills must be submitted within six months from the date of service
  // https://www.chministries.org/media/5189/chmguidelines.pdf
  let dueDate = formatISO(addMonths(parseDateInTimeZone(earliestExpenseDate), 6), { representation: 'date' })

  // exception: if the pending submission is to the HRA, then the due date is no later than feb 15th of the following year
  if (submissionType === 'HRA') {
    const feb15OfnextYear = `${parseDateInTimeZone(earliestExpenseDate).getFullYear() + 1}-02-15`
    dueDate = [dueDate, feb15OfnextYear].sort()[0]
  }

  return dueDate
}
