import { useState } from "react";
import { SubmissionModel, SubmittedSubmissionModel, isSubmitted, submittedToText } from "../reduxToolkit/submissionsSlice";
import { useAppSelector } from "../hooks/reduxToolkit";
import { formatDateInTimeZone } from "../../lib/formatDateInTimeZone";
import { byDateAscending, bySubmittedAtDescNullsFirst } from "../../lib/util/sort";
import { ToDoList } from "./todoList";
import { selectTodosForSubmission } from "../reduxToolkit/selectors/todos";
import { Decimal } from 'decimal.js';
import { formatCurrency } from "../../lib/formatCurrency";
import { isCompleteExpense } from "../reduxToolkit/expensesSlice";
import { calculateActualPaidAmount, calculateDiscountAmount, calculateRemainingOwedAmount } from "../../lib/expenses/amounts";
import { CurrencyLedger } from "./currencyLedger";
import { Attachment } from "./attachment";
import { RealizedTodoModel } from "../reduxToolkit/todosSlice";

import './submissionHistory.scss'

interface SubmissionHistoryProps {
  submissions: SubmissionModel[]
}

export function SubmissionHistory({ submissions }: SubmissionHistoryProps) {

  submissions = submissions.sort(bySubmittedAtDescNullsFirst)

  return <div className="submission-history">
    {submissions.filter(isSubmitted).map((submission, i) => {
      return <div className="row" key={submission.id}>
        <SubmissionHistoryRow submission={submission} initiallyExpanded={false} />
      </div>
    })}
  </div>
}

function SubmissionHistoryRow({ submission, initiallyExpanded }: { submission: SubmittedSubmissionModel, initiallyExpanded?: boolean }) {
  const { id, submitted_at } = submission

  const submittedTo = submittedToText(submission)
  const incompleteTodos = useAppSelector(selectTodosForSubmission(submission.id))

  const [expanded, setExpanded] = useState(initiallyExpanded || false)

  return <div key={id} className={`submission-history__row ${expanded && 'expanded'} col-12 card`}>
    <div className="card-body">
      <div  className={`submission-history__row-header`} onClick={!expanded ? ((e) => {e.preventDefault(); e.stopPropagation(); setExpanded(true) }) : undefined}>
        <div className="card-title">
          <p>
            {submitted_at ?
              `Submitted to ${submittedTo} on ${formatDateInTimeZone(submitted_at, { format: 'MMM dd' })}` :
              'Pending'}
          </p>
          {incompleteTodos.length > 0 &&
              <i className="material-icons text-danger">warning</i>}
        </div>
      </div>

      <button className="btn btn-link submission-history__row-collapse-toggle"
            aria-expanded={expanded} aria-controls={`#submission-history-${id}`}
            onClick={(e) => {e.preventDefault(); e.stopPropagation(); setExpanded(!expanded) }}
            >
            <i className={`material-icons`}>{expanded ? 'expand_less' : 'expand_more'}</i>
        </button>

      <div className={`submission-history__row-collapse collapse ${expanded && 'show'} card-text`} id={`submission-history-${id}`}
          data-id={id}>
        <SubmissionWidget submission={submission}
          incompleteTodos={incompleteTodos}
          expanded={expanded} />
      </div>
    </div>
  </div>
}

function SubmissionWidget({ submission, incompleteTodos, expanded }: { submission: SubmissionModel, incompleteTodos: RealizedTodoModel[], expanded: boolean }) {
  const expenses = useAppSelector((s) => s.expenses.expenses.filter((e) => submission.expense_ids?.includes(e.id)))
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => a.record_id === submission.id))
  
  const totalPaid = expenses.reduce((sum, expense) => {
    const amount = new Decimal(expense.paidAmount || '0');
    return sum.plus(amount);
  }, new Decimal(0));
  
  const sortedExpenses = expenses.filter(isCompleteExpense).sort(byDateAscending)
  
  /**
   * For a personal responibility reimbursement submission, the amount reimbursed is not the total of the expenses
   * So we have to figure out another way to show that.  For now, just hide it.
   */
  const isPersonalResponsibility = submission.submission_type === 'CHM-personal-responsibility'

  return <div className="submission-history__widget">
    {incompleteTodos.length > 0 &&
      <div className="submission-history__widget-todos">
        <div className="alert alert-warning">
          You have {incompleteTodos.length} incomplete {incompleteTodos.length === 1 ? 'task' : 'tasks'}.
        </div>
        <ToDoList todos={incompleteTodos} />
      </div>}
    {!isPersonalResponsibility && <div className="submission-history__widget-expenses">
      <h5>Total: {formatCurrency(totalPaid)}</h5>
      <div className="submission-history__widget-expenses-ledger">
        <div className="table-container">
          <CurrencyLedger
            columns={[
              null,
              ...sortedExpenses.map((expense) => ({
                title: expense.date ? formatDateInTimeZone(expense.date, { format: 'M/d/yyyy' }) : 'Unknown Date',
                columnClass: 'submission-history__widget-expenses-date'
              }))
            ]}
            rows={[
              { description: 'Listed',
                rowClass: 'submission-history__widget-expenses-listed',
                amounts: sortedExpenses.map((expense) => expense.listedAmount ? new Decimal(expense.listedAmount) : undefined)
              },
              { description: 'Discount',
                rowClass: 'submission-history__widget-expenses-discount',
                amounts: sortedExpenses.map((expense) => calculateDiscountAmount(expense))
              },
              { description: 'Paid',
                rowClass: 'submission-history__widget-expenses-paid',
                amounts: sortedExpenses.map((expense) => calculateActualPaidAmount(expense))
              },
              { description: 'Owed',
                rowClass: 'submission-history__widget-expenses-owed',
                amounts: sortedExpenses.map((expense) => calculateRemainingOwedAmount(expense))
              }
              ]}
            />
        </div>
      </div>
    </div>}

    {attachments.length > 0 && <div className="submission-history__widget-attachments mt-4">
      <h5>Attachments</h5>
      <div className="submission-history__widget-attachments-list">
        {attachments.map((attachment) => <Attachment key={attachment.id} attachment={attachment} />)}
      </div>
    </div>}
  </div>
}
