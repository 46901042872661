import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist'
import { onSyncDownComplete } from './actions/onSyncDownComplete'
import { replaceOrInsert } from '../../lib/util/replaceOrInsert'
import { RecordsThatHaveTodos, TodoAction, VirtualToDo } from '../../lib/todos/types'
import { redactObjectExceptKeys } from '../../lib/util/redact'
import { NotDeleted, TodoInsert, TodoRow, TodoUpdate } from '../../types/supabase'
import { DeepPartialNullable, NotNull } from '../../types/util'
import { present } from '../../lib/util/present'

export interface RealizedTodoModel {
  /**
   * UUID of the Database record backing this RealizedTodoModel
   */
  id: string
  membership_id: string
  created_at: string
  updated_at: string
  completed_at?: string | null
  deleted_at?: string | null

  /**
   * Foreign key to the VirtualToDo which created this RealizedTodoModel.
   * 
   * Manually created TODOs have a null key.
   * 
   * There is a unique index on this (excluding nulls)
   */
  key?: string | null
  title: string

  /**
   * The record that this TODO is associated with.
   * 
   * Manually created TODOs have a null record_id.
   */
  record_id?: string | null
  record_type?: RecordsThatHaveTodos | null
  todo_type?: string | null

  /** Whether this TODO should show a due date, or false if we lack the ability to determine a due date. */
  has_due_date: boolean
  /**
   * The due date for this TODO.
   *  If null and hasDueDate is true, the due date is ASAP.
   */
  due_date?: string | null
  
  /**
   * The list of todo keys that this todo depends on.  This one can't be completed until all
   * of the todos in this list are completed.
   */
  depends_on?: string[]
  
  /**
   * The action to take when this todo is completed.
   */
  action?: TodoAction | null
}

export function isRealizedTodoModel(todo: RealizedTodoModel | VirtualToDo): todo is RealizedTodoModel {
  return 'id' in todo
}

/**
 * A RealizedTodoModel that was created from a VirtualToDo automatically by the system.
 * 
 * VirtualTodos are always created in reference to a specific record, therefore they
 * always have the record_id and record_type fields.
 */
export interface RealizedTodoModelFromVirtualTodo extends RealizedTodoModel {
  key: string
  record_id: string
  record_type: RecordsThatHaveTodos
  todo_type: string
}

export function isRealizedTodoModelFromVirtualTodo(todo: RealizedTodoModel): todo is RealizedTodoModelFromVirtualTodo {
  return present(todo.key) && present(todo.record_id) && present(todo.record_type) && present(todo.todo_type)
}

export interface RealizedTodoModelManuallyCreated extends RealizedTodoModel {
  key?: null
  record_id?: null
  record_type?: null
  todo_type?: null
}

export type TodosSliceState = {
  /**
   * All TODOs for this membership that have been (or are in the process of being) created as rows in the database.
   */
  todos: Array<RealizedTodoModel>
}

const initialState: TodosSliceState = {
  todos: []
}

export type RealizedTodoModelInsertPayload = RealizedTodoModel
export type RealizedTodoModelUpdatePayload = NotNull<NotDeleted<TodoUpdate>, 'id' | 'updated_at'>

export const TodosSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    addTodo(state, action: PayloadAction<RealizedTodoModelInsertPayload>) {
      if (!state.todos) { state.todos = [] }
      const i = state.todos.findIndex((t) => t.id === action.payload.id)
      if (i >= 0) {
        state.todos[i] = action.payload
      } else {
        state.todos.push(action.payload)
      }
    },
    updateTodo(state, action: PayloadAction<RealizedTodoModelUpdatePayload>) {
      if (!state.todos) { state.todos = [] }
      const i = state.todos.findIndex((t) => t.id === action.payload.id)
      if (i >= 0) {
        state.todos[i] = {
          ...state.todos[i],
          ...action.payload
        }
      }
    },
    completeTodo(state, action: PayloadAction<{ id: string, updated_at: string, completed_at: string }>) {
      if (!state.todos) { state.todos = [] }
      const i = state.todos.findIndex((t) => t.id === action.payload.id)
      if (i >= 0) {
        state.todos[i].updated_at = action.payload.updated_at
        state.todos[i].completed_at = action.payload.completed_at
      }
    },
    deleteTodo(state, action: PayloadAction<{ id: string, updated_at: string, deleted_at: string }>) {
      if (!state.todos) { state.todos = [] }
      const i = state.todos.findIndex((t) => t.id === action.payload.id)
      if (i >= 0) {
        state.todos.splice(i, 1)
      }
    },
  },
  extraReducers: (builder) => {
    builder = builder.addCase(PURGE, (state) => {
      return initialState
    }).addCase(onSyncDownComplete, (state, action) => {
      state.todos = state.todos || []
      
      for (const todo of action.payload.todos?.updated || []) {
        replaceOrInsert(state.todos, todo)
      }
      for (const deletedTodo of action.payload.todos?.deleted || []) {
        const i = state.todos.findIndex((t) => t.id === deletedTodo.id)
        if (i >= 0) {
          state.todos.splice(i, 1)
        }
      }
    })
  },
})

// Action creators are generated for each case reducer function
export const {
  addTodo,
  deleteTodo,
  updateTodo,
  completeTodo
} = TodosSlice.actions

export type TodosSliceAction = ReturnType<typeof TodosSlice.actions[keyof typeof TodosSlice.actions]>

export function isTodosSliceAction(action: any): action is TodosSliceAction {
  return action.type?.startsWith(TodosSlice.name)
}

export default TodosSlice.reducer

export function redactTodos(state: TodosSliceState): DeepPartialNullable<TodosSliceState> {
  return {
    todos: state.todos?.map(redactTodo),
  }
}

export function redactTodo(todo: Partial<TodoRow>): DeepPartialNullable<TodoRow> {
  return {
    ...redactObjectExceptKeys(todo, 'id', 'created_at', 'updated_at', 'completed_at', 'deleted_at', 'record_id', 'record_type', 'has_due_date', 'due_date', 'key'),
  }
}

export function redactTodosSliceAction(action: TodosSliceAction) {
  switch(action.type) {
    case addTodo.type:
    case deleteTodo.type:
    case updateTodo.type:
    case completeTodo.type:
      return {
        type: action.type,
        payload: redactTodo(action.payload)
      }

    default:
      return {
        type: (action as any).type,
        payload: 'REDACTED'
      }
  }
}

// Assert we can assign a TodoRow to a RealizedTodoModel
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertTodoRowAssignable: RealizedTodoModel = {} as TodoRow

// Assert we can assign a RealizedTodoModel to a RealizedTodoModelInsertPayload
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertRealizedTodoModelInsertAssignable: TodoInsert = {} as RealizedTodoModelInsertPayload

// Assert we can assign a RealizedTodoModel to a RealizedTodoModelUpdatePayload
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _assertRealizedTodoModelUpdateAssignable: TodoUpdate = {} as RealizedTodoModelUpdatePayload
