import Decimal from "decimal.js"
import { tryParseDecimal } from "./tryParseDecimal"

interface CalculateableRow {listedAmount?: string | null, discountAmount?: string | null, paidAmount?: string | null, remainingOwedAmount?: string | null}

type ResultSet = {
  [K in keyof CalculateableRow]?: Decimal
}

export function calculateRemainingAmounts(row: CalculateableRow): ResultSet {
  const listedAmount = tryParseDecimal(row.listedAmount)
  const discountAmount = tryParseDecimal(row.discountAmount)
  const paidAmount = tryParseDecimal(row.paidAmount)
  const remainingOwedAmount = tryParseDecimal(row.remainingOwedAmount)
  
  if (!listedAmount) {
    // We can only calculate the listed amount if all the other values are present
    if (discountAmount && paidAmount && remainingOwedAmount) {
      return {listedAmount: discountAmount.add(paidAmount).add(remainingOwedAmount)}
    }
    return {}
  }
  
  // For each combination of the 3 unknown values, where the other 2 are known, calculate the unknown value
  
  const unknowns: Array<keyof CalculateableRow> = []
  const knowns: Array<keyof CalculateableRow> = []
  for (const key of ['discountAmount', 'paidAmount', 'remainingOwedAmount']) {
    const isParseable = tryParseDecimal(row[key as keyof CalculateableRow])
    if (isParseable) {
      knowns.push(key as keyof CalculateableRow)
    } else {
      unknowns.push(key as keyof CalculateableRow)
    }
  }
  
  if (unknowns.length == 3) {
    // not enough known values
    return {}
  }
  
  if (unknowns.length == 1) {
    let remainder = listedAmount
    for (const key of knowns) {
      const parsed = tryParseDecimal(row[key as keyof CalculateableRow])!
      remainder = remainder.sub(parsed)
    }
    return {[unknowns[0]]: remainder}
  }
  
  switch (unknowns.sort().join(',')) {
    case 'discountAmount,paidAmount':
        // In this case we know the listed amount and the remaining owed amount
      return {
        discountAmount: listedAmount.sub(remainingOwedAmount!),
        paidAmount: new Decimal(0)
      }
    case 'discountAmount,remainingOwedAmount':// In this case we know the listed amount and the paid amount
        return {
          discountAmount: listedAmount.sub(paidAmount!),
          remainingOwedAmount: new Decimal(0)
        }
    case 'paidAmount,remainingOwedAmount':  
      // In this case we know the listed amount and the discount amount
      return {
        paidAmount: listedAmount.sub(discountAmount!),
        remainingOwedAmount: new Decimal(0)
      }
    default:
      return {}
  }
  
}
