import type { AdvanceModel, AdvanceRequestModel } from "../advancesSlice"
import type { ExpenseModel } from "../expensesSlice"
import type { IncidentModel } from "../incidentsSlice"
import { RootState } from "../store"
import type { SubmissionModel } from "../submissionsSlice"
import { RealizedTodoModel } from "../todosSlice"

type RecordTypesToModelMap = {
  'expense': ExpenseModel,
  'incident': IncidentModel,
  'submission': SubmissionModel,
  'advance': AdvanceModel,
  'advance_request': AdvanceRequestModel,
  'todo': RealizedTodoModel,
}

export type AnyRecordType = keyof RecordTypesToModelMap

type Associateable<TRecordType extends AnyRecordType> = {
  record_type?: TRecordType | null,
  record_id?: string | null,
}

export function selectNotificationAssociatedRecord<TRecordType extends AnyRecordType>(
  association: Associateable<TRecordType>
): (s: RootState) => RecordTypesToModelMap[TRecordType] | null {
  function selectRecord(s: RootState): RecordTypesToModelMap[AnyRecordType] | null {
    if (!association.record_type || !association.record_id) {
      return null
    }

    switch (association.record_type) {
      case 'expense':
        return s.expenses.expenses.find(e => e.id === association.record_id) ?? null
      case 'incident':
        return s.incidents.incidents.find(i => i.id === association.record_id) ?? null
      case 'submission':
        return s.submissions.submissions.find(s => s.id === association.record_id) ?? null
      case 'advance':
        return s.advances.advances.find(a => a.id === association.record_id) ?? null
      case 'advance_request':
        return s.advances.advanceRequests.find(ar => ar.id === association.record_id) ?? null
      case 'todo':
        return s.todos.todos.find(t => t.id === association.record_id) ?? null
      default:
        return raiseUnknownNotificationRecordType(association.record_type)
    }
  }

  return selectRecord as (s: RootState) => RecordTypesToModelMap[TRecordType] | null
}

function raiseUnknownNotificationRecordType(x: never): never {
  throw new Error(`Unknown notification record type: ${x}`)
}
