import { useEffect, useState } from "react";
import { isSubmitted } from "../../../reduxToolkit/submissionsSlice";
import { requireIncident, RequireIncidentProvided } from "../../../wrappers/requireIncident";
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone";
import { formatCurrency } from "../../../../lib/formatCurrency";
import { Tooltip } from "../../../components/tooltip";
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit";
import { createAdvanceRequest, isPendingAdvanceRequest } from "../../../reduxToolkit/advancesSlice";
import { useUser } from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { present } from "../../../../lib/util/present";
import { createAttachment } from "../../../reduxToolkit/attachmentsSlice";
import { uniqBy } from "lodash";
import { useCustomization } from "../../../hooks/useCustomizations";
import { tryParseDecimal } from "../../../../lib/util/tryParseDecimal";
import Decimal from "decimal.js";

export function AdvanceRequestCreate({incident}: RequireIncidentProvided) {
  const chmSubmittedExpenseIDs = incident.submissions
    .filter(isSubmitted)
    .filter(submission => submission.submission_type === 'CHM' || submission.submission_type === 'CHM-addon')
    .flatMap(submission => submission.expense_ids)
    
  const hraSubmittedExpenseIDs = incident.submissions
    .filter(isSubmitted)
    .filter(submission => submission.submission_type === 'HRA')
    .flatMap(submission => submission.expense_ids)
    
  const alreadyAdvancedExpenseIDs = useAppSelector((s) => (s.advances?.advances || [])
    .flatMap(a => a.expense_ids))

  const [includedExpenseIDs, setIncludedExpenseIDs] = useState(
    chmSubmittedExpenseIDs.filter((id) => !alreadyAdvancedExpenseIDs.includes(id))
  )
  
  // Find all blobs that are attached to the incident directly, the included expenses, OR to any of the included expenses' submissions
  const attachableRecordIDs = [incident.id, ...includedExpenseIDs, ...includedExpenseIDs.flatMap(id => incident.expenses.find(e => e.id === id)?.submission_id)].filter(present)
  const attachmentsToCopy = useAppSelector((s) => (s.attachments?.attachments || [])
    .filter(attachment => attachableRecordIDs.includes(attachment.record_id)));

  const totalExpenseAmount = incident.expenses
    .reduce((total, expense) => total.plus(tryParseDecimal(expense.paidAmount) || new Decimal(0)), new Decimal(0))

  const totalAdvanceAmount = incident.expenses
    .filter(expense => includedExpenseIDs.includes(expense.id))
    .reduce((total, expense) => total.plus(tryParseDecimal(expense.paidAmount) || new Decimal(0)), new Decimal(0))
    
  const dispatch = useAppDispatch()
  const currentUser = useUser()
  const navigate = useNavigate()
  
  const advanceLimit = useCustomization('advances')?.advanceLimit
  const advanceLimitNumeric = advanceLimit ? tryParseDecimal(advanceLimit) : null
  const advanceLimitExceeded = advanceLimitNumeric ? totalAdvanceAmount.gt(advanceLimitNumeric) : false
  const actualAdvanceAccount: Decimal = advanceLimitExceeded && advanceLimitNumeric ? advanceLimitNumeric : totalAdvanceAmount
  
  // You can only have one open advance request at a time - if there's one, navigate to it
  const openAdvanceRequest = useAppSelector((s) => (s.advances?.advanceRequests || [])
    .filter((ar) => ar.incident_id === incident.id)
    .find(isPendingAdvanceRequest))
  useEffect(() => {
    if (openAdvanceRequest) {
      navigate(`/incidents/${incident.id}`)
      toast.error('You already have an open advance request for this incident.')
    }
  }, [])

  const submitRequest = () => {
    if (includedExpenseIDs.length === 0) {
      toast.error('You must select at least one expense to request an advance for.')
      return
    }
    
    const id = uuidv4()
    const now = new Date().toISOString()
    dispatch(createAdvanceRequest({
      id,
      created_at: now,
      updated_at: now,
      incident_id: incident.id,
      expense_ids: includedExpenseIDs,
      created_by_user_id: currentUser.id,
      membership_id: incident.membership_id,
      amount: totalAdvanceAmount.toFixed(2),
    }))
    
    // Create an attachment for each blob key to the advance request
    uniqBy(attachmentsToCopy, 'blob_key').forEach(attachment => {
      dispatch(createAttachment({
        id: uuidv4(),
        blob_key: attachment.blob_key,
        record_id: id,
        table_name: 'advance_requests',
        created_at: now,
        updated_at: now,
        created_by_user_id: currentUser.id,
        membership_id: incident.membership_id,
        purpose: attachment.purpose,
      }))
    })
    
    navigate(`/incidents/${incident.id}`)
  }

  return <div className="advance-request-create">
    <div className="row advance-request-create__header-row">
      <div className="col-12 advance-request-create__header">
        <h1>Request an Advance</h1>
      </div>
    
      <div className='col-12 col-lg-6 advance-request-create__info'>
        <p>
          <strong>Incident:</strong> {incident.description}<br/>
          <strong>Total Expense Amount:</strong> {formatCurrency(totalExpenseAmount)}<br/>
          <strong>Advance Amount:</strong>{' '}{formatCurrency(totalAdvanceAmount)}
          {advanceLimitExceeded && <Tooltip tooltip="You are attempting to request an advance for more than your plan administrator has allowed.">
            <i className="material-icons text-warning ms-2">warning</i>
          </Tooltip>}
        </p>
      </div>
    
    </div>
    
    <div className="row">
      <form onSubmit={(e) => {
        e.preventDefault()
        submitRequest()
      }}>
        <div className="col-12 mt-2">
          <h6>Which expenses do you need an advance for?</h6>
          
          <ul className="checklist advance-request-create__expenses mt-2">
            {incident.expenses.map(expense => {
              const {id, date, paidAmount, provider} = expense
              
              const isIncluded = includedExpenseIDs.includes(expense.id)
              
              let disabled = false
              let disabledReason = ''
              if (!isIncluded) {
                if (hraSubmittedExpenseIDs.includes(expense.id)) {
                  disabled = true
                  disabledReason = "You've already been reimbursed for this expense via your HRA."
                } else if (!expense.submitted_at) {
                  disabled = true
                  disabledReason = "You must first submit this expense to CHM before requesting an advance."
                } else if (alreadyAdvancedExpenseIDs.includes(expense.id)) {
                  disabled = true
                  disabledReason = "You've already been advanced for this expense."
                }
              }
              
              return <Tooltip key={expense.id} tooltip={disabledReason}
                  htmlElement='li'
                  className={`checklist__item ${disabled && 'disabled'}`}
                  onClick={() => {
                    if (disabled) { return }
                    if (isIncluded) {
                      setIncludedExpenseIDs(includedExpenseIDs.filter(id => id !== expense.id))
                    } else {
                      setIncludedExpenseIDs([...includedExpenseIDs, expense.id])
                    }
                  }}
                >
                  <div className="advance-request-create__checklist-item__description">
                    {date ? formatDateInTimeZone(date, { format: 'MM/dd/yyyy' }) : 'Unknown Date'}
                    &nbsp;-&nbsp;
                    {paidAmount ? formatCurrency(paidAmount) : 'Unknown Amount'}
                    <br />
                    <span>{provider}</span>
                    <br/>
                  </div>
                
                  <div className='advance-request-create__checklist-item__form-check-wrapper'>
                    <div className='form-check form-check-inline'>
                      <input className={`form-check-input ${disabled && 'disabled'}`}
                        type='checkbox'
                        disabled={disabled}
                        name={`${id}_included`} id={`${id}_included`}
                        value='included'
                        checked={isIncluded}></input>
                    </div>
                  </div>
                </Tooltip>
            })}
          </ul>
        </div>

        {advanceLimitExceeded && advanceLimitNumeric && <div className="col-12 mt-2">
            Your plan administrator has set a maximum advance amount of {formatCurrency(advanceLimitNumeric)}.
            If you need more than this, please go ahead and request the maximum amount and then contact your plan administrator separately.
        </div>}
        
        <div className="col-12 mt-2">
          <button className="btn btn-primary advance-request-create__submit">Submit Request for {formatCurrency(actualAdvanceAccount)}</button>
        </div>
      </form>
    </div>
  </div>
}

export default requireIncident(AdvanceRequestCreate);
