import { byTodoDueDateAscending } from "../../../lib/util/sort";
import { RootState } from "../store";
import { RealizedTodoModel } from "../todosSlice";
import { selectAllTodosReferencingIncident } from "./selectAllTodosReferencingIncident";

export function selectFirstTodoReferencingIncident(incidentId: string): (s: RootState) => RealizedTodoModel | undefined {
  return (s: RootState) => {
    return selectAllTodosReferencingIncident(incidentId)(s)
      .sort(byTodoDueDateAscending)[0]
  }
}
