import { RootState } from "../store"
import { createToDosForExpense } from "../../../lib/todos/expense"
import { createToDosForIncident } from "../../../lib/todos/incident"
import { createToDosForSubmission } from "../../../lib/todos/submission"
import { VirtualToDo } from "../../../lib/todos/types"
import { createToDosForAdvance } from "../../../lib/todos/advance"

/**
 * Given the current Redux state, returns a list of all of the incomplete ToDos
 */
export function selectVirtualToDos(s: RootState): VirtualToDo[] {
  const todos: VirtualToDo[] = []
  for (const expense of s.expenses.expenses) {
    todos.push(...createToDosForExpense(s, expense))
  }
  for (const incident of s.incidents.incidents) {
    todos.push(...createToDosForIncident(s, incident))
  }
  for (const submission of s.submissions.submissions) {
    todos.push(...createToDosForSubmission(s, submission))
  }
  // Add todos for advances
  for (const advance of (s.advances?.advances || [])) {
    todos.push(...createToDosForAdvance(s, advance))
  }
  return todos
}

export function selectVirtualToDosForExpense(expenseId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const expense = s.expenses.expenses.find((e) => e.id === expenseId)
    if (!expense) { return [] }

    const expenseTodos = Array.from(createToDosForExpense(s, expense))

    return expenseTodos
  }
}

export function selectVirtualToDosForIncident(incidentId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const incident = s.incidents.incidents.find((i) => i.id === incidentId)
    if (!incident) { return [] }

    const incidentTodos: VirtualToDo[] = Array.from(createToDosForIncident(s, incident))

    return incidentTodos
  }
}

export function selectVirtualToDosForSubmission(submissionId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const submission = s.submissions.submissions.find((s) => s.id === submissionId)
    if (!submission) { return [] }

    const submissionTodos: VirtualToDo[] = Array.from(createToDosForSubmission(s, submission))

    return submissionTodos
  }
}

export function selectVirtualToDosForAdvance(advanceId: string): (s: RootState) => VirtualToDo[] {
  return (s: RootState) => {
    const advance = s.advances?.advances?.find((a) => a.id === advanceId)
    if (!advance) { return [] }

    return Array.from(createToDosForAdvance(s, advance))
  }
}

