import { createAction } from "@reduxjs/toolkit";
import type { SyncAction, SyncableTable } from "../../../lib/sync/action";
import type { ExpenseRow, IncidentRow, AttachmentRow, SubmissionRow, AdvanceRequestRow, AdvanceRow, NotificationRow, ProviderRow, TodoRow } from "../../../types/supabase";

export interface OnSyncDownCompletePayload
{
  expenses?: {
    updated?: ExpenseRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  incidents?: {
    updated?: IncidentRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  attachments?: {
    updated?: AttachmentRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  submissions?: {
    updated?: SubmissionRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  },
  advances?: {
    updated?: AdvanceRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  advance_requests?: {
    updated?: AdvanceRequestRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  notifications?: {
    updated?: NotificationRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  providers?: {
    updated?: ProviderRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }
  todos?: {
    updated?: TodoRow[],
    deleted?: Array<{ id: string, updated_at: string, deleted_at: string }>,
  }

  syncActions?: SyncAction<SyncableTable>[]
}

export const onSyncDownComplete = createAction<OnSyncDownCompletePayload, 'supabase/onSyncDownComplete'>('supabase/onSyncDownComplete')
