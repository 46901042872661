import { v4 as uuidv4 } from 'uuid'
import { addTodo, completeTodo, deleteTodo, RealizedTodoModel, RealizedTodoModelUpdatePayload, TodosSliceAction, updateTodo } from '../../app/reduxToolkit/todosSlice'
import { VirtualToDo } from './types'
import { isEqual } from 'lodash'
import { AdvancesSliceAction, isAdvancesSliceAction } from '../../app/reduxToolkit/advancesSlice'
import { ExpensesSliceAction, isExpensesSliceAction, deleteExpense } from '../../app/reduxToolkit/expensesSlice'
import { IncidentsSliceAction, isIncidentsSliceAction, deleteIncident } from '../../app/reduxToolkit/incidentsSlice'
import { SubmissionsSliceAction, isSubmissionsSliceAction, deleteSubmission } from '../../app/reduxToolkit/submissionsSlice'
import { AnyAction } from '@reduxjs/toolkit'

/**
 * Compares virtual todos against realized todos and generates actions to reconcile them.
 * 
 * @param virtualTodos The list of virtual todos generated from the current state
 * @param realizedTodos The list of realized todos from the store
 * @returns Array of actions to reconcile the differences
 */
export function reconcileTodos(
  virtualTodos: VirtualToDo[],
  realizedTodos: RealizedTodoModel[],
  /**
   * The Redux action which triggered this reconciliation.
   */
  action: AnyAction,
  context: {
    membershipId: string,
    now: Date,
}): TodosSliceAction[] {
  const actions: TodosSliceAction[] = []
  const now = context.now.toISOString()

  // First handle updates and deletions of existing todos
  realizedTodos.forEach(realizedTodo => {
    // Skip manually created todos (no key)
    if (!realizedTodo.key) {
      return
    }
    
    // skip deleted todos
    if (realizedTodo.deleted_at) {
      return
    }
    
    const virtualTodo = virtualTodos.find(v => v.key === realizedTodo.key)
    
    // If the todo is completed, and the virtual todo reappears, then un-complete it.
    if (realizedTodo.completed_at) {
      if (virtualTodo) {
        actions.push(updateTodo({
          id: realizedTodo.id,
          updated_at: now,
          completed_at: null
        }))
        return
      }
    } else if (!virtualTodo) {
      // If the action is a delete action, then the reason this virtual todo no longer exists is because the record it was associated with was deleted.
      // In this case, we should delete the todo rather than completing it.
      if (isDeleteAction(action)) {
        actions.push(deleteTodo({
          id: realizedTodo.id,
          updated_at: now,
          deleted_at: now
        }))
        return
      } else {
        // If virtual todo no longer exists and the realized todo isn't already deleted, then it is assumed to be completed
        actions.push(completeTodo({
          id: realizedTodo.id,
            updated_at: now,
            completed_at: now
          }))
        return
      }
    }

    // If virtual todo exists, check for changes
    if (virtualTodo) {
      const changes: RealizedTodoModelUpdatePayload = {
        id: realizedTodo.id,
        updated_at: now,
      }
      let hasChanges = false

      // Compare each field that could change
      const fieldsToCompare = [
        'title',
        'record_id',
        'record_type',
        'todo_type',
        'has_due_date',
        'due_date',
        'depends_on',
        'action'
      ] as const

      fieldsToCompare.forEach(field => {
        const virtualValue = virtualTodo[field]
        const realizedValue = realizedTodo[field]

        // Deep compare for arrays/objects, strict compare for primitives
        const isDifferent = !isEqual(virtualValue, realizedValue)

        if (isDifferent) {
          changes[field] = virtualValue as any
          hasChanges = true
        }
      })

      if (hasChanges) {
        actions.push(updateTodo(changes))
      }
    }
  })

  // Then create new todos for any remaining virtual todos
  virtualTodos.forEach(virtualTodo => {
    const existingTodo = realizedTodos.find(r => 
      r.key === virtualTodo.key
    )

    if (!existingTodo) {
      actions.push(addTodo({
        id: uuidv4(),
        membership_id: context.membershipId,
        created_at: now,
        updated_at: now,
        key: virtualTodo.key,
        title: virtualTodo.title,
        record_id: virtualTodo.record_id,
        record_type: virtualTodo.record_type,
        todo_type: virtualTodo.todo_type,
        has_due_date: virtualTodo.has_due_date,
        due_date: virtualTodo.due_date,
        depends_on: virtualTodo.depends_on,
        action: virtualTodo.action
      }))
    }
  })

  return actions
} 


type ActionAffectingTodos = 
  | ExpensesSliceAction
  | IncidentsSliceAction
  | SubmissionsSliceAction
  | AdvancesSliceAction

export function isActionAffectingTodos(action: any): action is ActionAffectingTodos {
    // This should match the RecordsThatHaveTodos enum
    return isExpensesSliceAction(action) ||
        isIncidentsSliceAction(action) ||
        isSubmissionsSliceAction(action) ||
        isAdvancesSliceAction(action)
}

const deleteActions = [
  deleteExpense,
  deleteIncident,
  deleteSubmission,
] as const

type DeleteAction = typeof deleteActions[number]

function isDeleteAction(action: any): action is DeleteAction {
  return deleteActions.map<string>(a => a.type).includes(action.type)
}