import React, { useCallback } from "react";
import type { Session } from '@supabase/supabase-js';
import { useSentry } from "../../providers/sentry";

const SignInContext = React.createContext<{
  onLoginSuccess: (data: {session: Session}, navigateTo?: string) => any
}>({
  onLoginSuccess: () => {}
});

export function SignInContextProvider({
  children,
  onLoginSuccess
}: {
  children: React.ReactNode
  onLoginSuccess: (data: {session: Session}, navigateTo?: string) => any
}) {

  const Sentry = useSentry()
  const _onLoginSuccess = useCallback((data: {session: Session}, navigateTo?: string) => {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Login successful',
      level: 'info',
      data: {
        user: data?.session?.user ? {
          id: data.session.user.id,
          aud: data.session.user.aud,
          email: data.session.user.email,
          phone: data.session.user.phone,
        } : undefined,
        navigateTo,
      }
    })
    onLoginSuccess(data, navigateTo)
  }, [onLoginSuccess])

  return (
    <SignInContext.Provider value={{ onLoginSuccess: _onLoginSuccess }}>
      {children}
    </SignInContext.Provider>
  )
}

export function useSignInContext() {
  return React.useContext(SignInContext)
}
