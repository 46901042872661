import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAppSelector } from "../hooks/reduxToolkit";
import { present } from "../../lib/util/present";
import { useState, useEffect, useRef } from "react";
import { Tooltip } from "./tooltip";
import { useSentry } from "../providers/sentry";
import { throttle } from "async-toolbox";

import './alertBanner.scss'

export default function AlertBanner() {
  const isAccountDeletionRequested = useAppSelector((s) => present(s.membership?.profile?.account_deletion_requested_at))
  const isMaintenanceMode = useIsMaintenanceMode();

  let banner: JSX.Element | null = null;
  if (isAccountDeletionRequested) {
    banner = <Link to={'/deleteAccount'} className="a-block">
      <div className="bg-danger text-white text-center">
        Account Deletion Pending
      </div>
    </Link>
  } else if (isMaintenanceMode) {
    banner = 
    <Tooltip tooltip="Some features may be temporarily unavailable while we perform maintenance."
      htmlElement="div"
      className="bg-warning text-white text-center">
        <span>The system is under maintenance.</span>
    </Tooltip>
  }

  return <div className="alert-banner">
      {banner}

      <Toaster />
    </div>
}


function useIsMaintenanceMode() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const Sentry = useSentry();

  useEffect(() => {

    function doCheck() {
      checkMaintenanceMode()
        .then((isActive) => {
          setIsMaintenanceMode((wasActive) => {
            if (wasActive && !isActive) {
              toast.success('Maintenance has ended.');
            }
            return isActive;
          });
          if (isActive) {
            // check every second
            timeout.current = setTimeout(doCheck, 1_000);
          } else {
            // check every minute
            timeout.current = setTimeout(doCheck, 60_000);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    }

    // Check once on load, then check every second only if we're in maintenance mode
    doCheck();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    }
  }, []);

  return isMaintenanceMode;
}

const checkMaintenanceMode = throttle(async () => {
  const res = await fetch('/maintenance-mode-active', {
    cache: 'no-store'
  });
  
  if (res.ok) {
    return true;
  } else {
    if (res.status === 404) {
      return false;
    } else {
      throw new Error('Failed to check maintenance mode');
    }
  }
}, 1000);