import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxToolkit"
import { formatDateInTimeZone } from "../../../../lib/formatDateInTimeZone"
import { useCallback, useEffect, useState } from "react"
import { useUser } from "../../../hooks/useUser"
import { requireIncident } from "../../../wrappers/requireIncident"
import { RequireSubmissionProvided } from "../../../wrappers/requireSubmission"
import { completeSubmission } from "../../../reduxToolkit/actions/completeSubmission"
import { isSubmitted } from "../../../reduxToolkit/submissionsSlice"
import { usePendingExpenses } from "../../../hooks/usePendingExpenses"
import { useLoadBlobs } from "../../../hooks/useLoadBlobs"
import { useCustomization } from "../../../hooks/useCustomizations"
import { ExpenseTable } from "../../../components/expenseTable"
import { isMobile } from "../../../../lib/util/isMobile"
import { useAsyncAction } from "../../../hooks/useAsyncAction"
import { useClient } from "../../../providers/supabase"
import { SubmissionById } from "../../../reduxToolkit/selectors/submissionById"

import './submitToHra.scss'

interface SubmitToHRAProps extends RequireSubmissionProvided {
}

export function SubmitToHRA({submission}: SubmitToHRAProps) {
  const dispatch = useAppDispatch()
  const user = useUser()
  const navigate = useNavigate()
  
  const hra = useCustomization('hra')

  // Redirect if already submitted
  useEffect(() => {
    if (isSubmitted(submission)) {
      navigate(`/incidents/${submission.incident_id}`)
    }
  }, [submission, navigate])

  const incident = submission.incident

  const pendingExpenses = usePendingExpenses(submission)
  const [canSubmit, setCanSubmit] = useState(false)

  const allAttachmentRecords = [submission.id, submission.incident_id, ...(submission.expense_ids || []), ...pendingExpenses.map((e) => e.id)]
  const attachments = useAppSelector((s) => s.attachments.attachments.filter((a) => allAttachmentRecords.includes(a.record_id)))
  const [blobs, { loading: blobsLoading }] = useLoadBlobs(attachments.map((a) => a.blob_key))
  
  if (!hra?.hasHra) {
    throw new Error('This page should not be shown if the HRA is not enabled')
  }

  const finishSubmission = useCallback(() => {
    dispatch(completeSubmission({
      submission: submission,
      attachments,
      pendingExpenses: pendingExpenses,
      submittedByUserId: user?.id || '',
    }))
  }, [attachments, dispatch, pendingExpenses, submission, user?.id])

  const cancelSubmission = useCallback(() => {
    const clickedOK = window.confirm('You haven\'t submitted all expenses. Are you sure you want to cancel?\n' +
      'Click "OK" to stay here and "Cancel" to leave this page.')

    if (!clickedOK) {
      navigate(`/incidents/${incident.id}`)
    }
  }, [incident.id, navigate])

  return <div className="row submit-to-hra">
    <div className="col-12">
      <button type="button" className="btn-close float-end" aria-label="Close"
        onClick={cancelSubmission}></button>
      <h2>Submit to HRA</h2>
      
      {isMobile() ?
        <EmailMyselfALink submission={submission} /> :
        <p>
          <a href={hra.hraUrl} target="_blank" rel="noreferrer">
            Open this link to submit your claim.
          </a>
        </p>
      }
    </div>
      
    <div className="submit-to-hra__files col-12">
      <h4 className="mt-4">Your Medical Bills</h4>
      <ExpenseTable
        expenses={pendingExpenses}
        displayOptions={{ hideIncident: true, expandAll: !isMobile() }}
        disableEdit={true}
      />
    </div>
    
    <div className="col-12">
      <p>
        <Link to={`/incidents/${incident.id}`} className={`btn btn-primary`}
          onClick={finishSubmission}>I've submitted my receipts to the HRA</Link>
      </p>
    </div>
  </div>
}

export default requireIncident(SubmitToHRA)

function EmailMyselfALink({ submission }: { submission: SubmissionById }) {
  const nav = useLocation()
  const client = useClient()
  
  const [emailMeState, emailMe] = useAsyncAction(async () => {
    const origin = typeof window !== 'undefined' ? window.location.origin : 'https://app.healthsharetech.com'
    const url = new URL(nav.pathname, origin)
    url.hash = nav.hash
    url.search = nav.search
    
    const { error } = await client.functions.invoke('email-myself', {
      body: JSON.stringify({
        subject: `Your Requested Submission Link`,
        html: `
          <p>
          You requested we send you a direct link to your in-progress submission, so that you can continue on your computer.
          Here is your link for this incident:
          </p>
          <p>
          ${submission.incident.description} on ${formatDateInTimeZone(submission.incident.start_date, { format: 'yyyy-MM-dd' })}
          </p>
          <a href="${url.href}">${url.href}</a>
        `
      })
    })
    if (error) { throw new Error(error.message) }
    
    return true
  }, [nav, client, submission])
  
  return <>
      <p>
        The HRA portal doesn't work well on mobile devices.
        We can email you a link to this submission so you can continue
        on your computer.
        <br />
        {emailMeState.data ?
          <>
            <br />
            <span className="text-success">Email sent!</span> Check your inbox for a message from us.<br/>
            Didn't get it? <button onClick={emailMe} className={`btn btn-link ${emailMeState.loading && 'disabled'}`}>
              Send the email again
            </button>
          </> :
          <button onClick={emailMe} className={`btn btn-link ${emailMeState.loading && 'disabled'}`}>
            Email Me a Link to this Page
          </button>}
        {emailMeState.error && <p className="text-danger">{emailMeState.error.message}</p>}
      </p>
    </>
}
