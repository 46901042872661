import { useTodoCompletion } from "../hooks/todos/useTodoCompletion";
import { RealizedTodoModel } from "../reduxToolkit/todosSlice";
import { present } from "../../lib/util/present";

import './todoList.scss'

interface ToDoListProps {
  todos: RealizedTodoModel[]
}

export function ToDoList({todos}: ToDoListProps) {

  const [onClick, _, modal] = useTodoCompletion()

  const incompleteTodos = todos.filter((todo) => !present(todo.completed_at))
  const completedTodos = todos.filter((todo) => present(todo.completed_at))

  return <>
    <div className="todo-list">
      {incompleteTodos.map((todo) => <ToDoListItem
        key={todo.id}
        item={todo}
        onClick={onClick}
        />)}

      {completedTodos.map((todo) => <ToDoListItem
        key={todo.id}
        item={todo}
        complete
        />)}
    </div>

    {modal}
  </>
}

interface ToDoListItemProps {
  item: RealizedTodoModel

  complete?: boolean

  onClick?: (item: RealizedTodoModel) => any
}

export function ToDoListItem({item, complete, onClick}: ToDoListItemProps) {

  return <div className={`todo-list-item ${complete ? 'complete' : 'incomplete'}`}
      onClick={onClick ? (() => { onClick(item) }) : undefined}>
    <div className="todo-list-item__header">
      <i className="material-icons">{
        complete ? 'check_box' : 'check_box_outline_blank'
      }</i>
      <span>{item.title}</span>
    </div>
  </div>
}
