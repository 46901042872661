import { isRealizedTodoModelFromVirtualTodo, RealizedTodoModel } from "../todosSlice"
import { RootState } from "../store"
import { present } from "../../../lib/util/present"
import { byTodoDueDateAscending } from "../../../lib/util/sort"

export interface TodosOptions {
  completed_since?: Date
}

export function selectTodos(options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  return (s: RootState) => {
    return (s.todos?.todos || [])
      .filter((todo) => {
        if (!present(todo.completed_at)) {
          return true
        }

        if (options.completed_since) {
          return new Date(todo.completed_at) >= options.completed_since
        }

        return false
      })
  }
}

export function selectEarliestTodo(options: TodosOptions = {}): (s: RootState) => RealizedTodoModel | undefined {
  return (s: RootState) => {
    return selectTodos(options)(s).sort(byTodoDueDateAscending)[0]
  }
}

export function selectTodoCount(options: TodosOptions = {}): (s: RootState) => number {
  return (s: RootState) => {
    return selectTodos(options)(s).length
  }
}

export function selectTodosForExpense(expenseId: string, options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  return (s: RootState) => {
    return selectTodos(options)(s).filter((todo) => todo.record_type === 'expense' && todo.record_id === expenseId)
  }
}

export function selectTodosForIncident(incidentId: string, options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  return (s: RootState) => {
    return selectTodos(options)(s).filter((todo) => todo.record_type === 'incident' && todo.record_id === incidentId)
  }
}

export function selectTodosForSubmission(submissionId: string, options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  return (s: RootState) => {
    return selectTodos(options)(s).filter((todo) => todo.record_type === 'submission' && todo.record_id === submissionId)
  }
}

export function selectTodosForAdvance(advanceId: string, options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  return (s: RootState) => {
    return selectTodos(options)(s).filter((todo) => todo.record_type === 'advance' && todo.record_id === advanceId)
  }
}

/**
 * Given a realized todo in the database, calculates the set of VirtualTodos that it depends on.  Then, maps those
 * virtual todos back to their realized database rows.
 */
export function selectDependentTodos(todo: RealizedTodoModel | undefined, options: TodosOptions = {}): (s: RootState) => RealizedTodoModel[] {
  if (!todo) {
    return () => []
  }

  if (!isRealizedTodoModelFromVirtualTodo(todo)) {
    return () => []
  }
  
  const dependsOn = todo.depends_on
  if (!dependsOn) {
    return () => []
  }

  return (s: RootState) => {
    return selectTodos(options)(s).filter((todo) => todo.key && dependsOn.includes(todo.key))
  }
}
