import { useCallback, useEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch, useAppSelector } from "../../hooks/reduxToolkit"
import { present } from "../../../lib/util/present"
import { selectPatients } from "../../reduxToolkit/selectors/dependents"
import { PatientSelect, addIdToPatient } from "../formComponents/patientSelect"
import { NotNull } from "../../../types/util"
import { ExpenseModel, isTextractJobFailed, isTextractPending, updateExpense } from "../../reduxToolkit/expensesSlice"
import { parseDollarAmount } from '../../../lib/util/parse';
import uniq from 'lodash/uniq';
import { assert } from "../../../lib/util/assert"
import { IncidentInsertPayload, addIncident } from "../../reduxToolkit/incidentsSlice";
import { IncidentInputGroup, IncidentInputGroupValue } from "../formComponents/incidentInputGroup";
import { Tooltip } from "../tooltip";
import { useCustomization } from "../../hooks/useCustomizations";
import Decimal from "decimal.js";
import { ProviderSelect } from '../formComponents/providerSelect'
import { calculateRemainingAmounts } from "../../../lib/util/calculateRemainingAmounts";
import { entries } from "lodash";

import './editExpenseRowForm.scss'

export type EditExpenseOnUpdatedFn = (expense: ExpenseModel, incident?: IncidentInsertPayload | null) => void

type EditExpenseFormModel = {
  id: string
  incident_id?: string | null
  date?: string | null
  listedAmount?: string | null
  discountAmount?: string | null
  paidAmount?: string | null
  remainingOwedAmount?: string | null
  patient_name?: string | null
  patient_dob?: string | null
  provider?: string | null
  provider_id?: string | null
  paid_with_hra?: boolean | null
  is_prepayment_agreement?: boolean | null
}
type ValidationErrors = {
  [key in keyof EditExpenseFormModel]?: string
}

const EditExpenseFormFields: Array<keyof EditExpenseFormModel> = [
  'id',
  'incident_id', 
  'date', 
  'listedAmount', 
  'discountAmount', 
  'paidAmount', 
  'remainingOwedAmount', 
  'patient_name', 
  'patient_dob', 
  'provider', 
  'provider_id',
  'paid_with_hra', 
  'is_prepayment_agreement'
]

function isEditExpenseFormField(field: string): field is keyof EditExpenseFormModel {
  return EditExpenseFormFields.includes(field as keyof EditExpenseFormModel)
}

export interface EditExpenseRowFormProps extends React.PropsWithChildren {
  expense: ExpenseModel

  disabledFields?: Array<keyof EditExpenseFormModel>

  onSubmit?: EditExpenseOnUpdatedFn

  validateOnMount?: boolean
}

export function EditExpenseRowForm({
  onSubmit,
  expense,
  disabledFields,
  validateOnMount,
  children
}: EditExpenseRowFormProps) {
  const dispatch = useAppDispatch()
  const userId = useAppSelector((s) => s.membership.userId)
  const patients = useAppSelector(selectPatients).map(addIdToPatient)
  
  const hraCustomizations = useCustomization('hra')
  const canChoosePaidWithHra = hraCustomizations?.providesHraCard

  const [row, setRow] = useState<EditExpenseFormModel>(expenseModelToFormModel(expense))
  const [wasValidated, setWasValidated] = useState(false)
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({})
  const clearValidationError = useCallback((field: keyof ValidationErrors) => {
    setValidationErrors((e) => ({...e, [field]: undefined}))
  }, [setValidationErrors])

  const formRef = useRef<HTMLFormElement>(null)
  useEffect(() => {
    if (!validateOnMount) { return }
    if (!formRef.current) { return }
    validateRow(row, formRef, setValidationErrors)
  }, [])

  // Update the row if the underlying expense changes, e.g. due to Textract completing
  useEffect(() => {
    setRow((r) => {
      const newRow: EditExpenseFormModel = {...r}
      for (const [keyStr, value] of Object.entries(expense)) {
        const key = keyStr as keyof ExpenseModel
        if (!isEditExpenseFormField(key)) {
          continue
        }

        // Change the form value only if the user hasn't set it
        // i.e. the current value is "undefined"
        if (
          (value != null && typeof value != 'undefined') &&
            (newRow[key] == null || typeof newRow[key] == 'undefined')
        ) {
          console.debug('setting', key, 'to', value, 'from', newRow[key]);
          (newRow as any)[key] = value
        }
      }
      return newRow
    })
    // The expense version is described by its ID and updated-at timestamp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expense.updated_at])
  
  const initialIncident = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === row.incident_id))
  const [selectedIncident, setSelectedIncident] = useState<IncidentInputGroupValue | null | undefined>(initialIncident)

  const currentIncidentId = selectedIncident?.id || row.incident_id
  const incidentIsMaternity = useAppSelector((s) => s.incidents.incidents.find((i) => i.id === currentIncidentId)?.is_maternity)

  /**
   * Overrides the default setter to also do automatic calculation & validation
   */
  const onBlur = () => {
    // Calculate the paid & owed amounts based on the new values if they haven't already been set.
    const resultSet = calculateRemainingAmounts(row)
    for (const [key, value] of entries(resultSet)) {
      if (!value) {
        continue
      }
      setRow((r) => ({...r, [key]: value.toFixed(2).replace(/\.00$/, '')}))
      clearValidationError(key as keyof ValidationErrors)
    }
    
    // Check whether the amounts all total up correctly
    const remainingOwedAmount = tryParseDecimal(row.remainingOwedAmount)
    const discountAmount = tryParseDecimal(row.discountAmount)
    const listedAmount = tryParseDecimal(row.listedAmount)
    const paidAmount = tryParseDecimal(row.paidAmount)
    
    if (listedAmount && discountAmount && paidAmount && remainingOwedAmount) {
      if (!listedAmount.sub(discountAmount).sub(paidAmount).eq(remainingOwedAmount)) {
        setValidationErrors((e) => ({...e, remainingOwedAmount: 'The amounts do not total up correctly'}))
      } else {
        clearValidationError('remainingOwedAmount')
      }
    }
  }
  
  const doUpdate = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!validateRow(row, formRef, setValidationErrors)) { return }

    let incidentId: string | null | undefined = selectedIncident && selectedIncident.id
    let addIncidentAction: ReturnType<typeof addIncident> | undefined = undefined
    const now = new Date().toISOString()

    const newIncident = selectedIncident && !present(selectedIncident.id)
    if (newIncident) {
      assert(selectedIncident.description)

      incidentId = uuidv4()

      addIncidentAction = dispatch(addIncident({
        id: incidentId,
        updated_at: now,
        created_at: now,

        membership_id: expense.membership_id,
        created_by_user_id: userId,
        start_date: row.date,
        patient_dob: row.patient_dob,
        patient_name: row.patient_name,
        description: selectedIncident.description,
      }))
    }
    
    const remainingOwedAmount = tryParseDecimal(row.remainingOwedAmount)
    const discountAmount = tryParseDecimal(row.discountAmount)
    const listedAmount = tryParseDecimal(row.listedAmount)
    const paidAmount = tryParseDecimal(row.paidAmount)

    const isFullyPaid = remainingOwedAmount?.eq(new Decimal(0))
    const postDiscountAmount = (listedAmount && discountAmount) ? listedAmount.sub(discountAmount).toFixed(2) : undefined

    const newExpenseState: ExpenseModel = {
      ...expense,
      updated_at: now,
      
      incident_id: incidentId || null,
      
      date: row.date,
      
      listedAmount: listedAmount?.toFixed(2),
      
      // Legacy naming issue: "paidAmount" is the amount after discounts
      paidAmount: postDiscountAmount,
      
      patient_dob: row.patient_dob,
      patient_name: row.patient_name,
      provider: row.provider,
      provider_id: row.provider_id,

      paid_with_hra: row.paid_with_hra,
      is_prepayment_agreement: row.is_prepayment_agreement,
      
      is_fully_paid: isFullyPaid
    }
    
    // If they went back and changed the paidAmount, we need to revise the payment history
    if (paidAmount) {
      let revisePaymentHistory = false
      if (!expense.payment_history) {
        revisePaymentHistory = true
      } else {
        const hasAnythingChanged = 
          postDiscountAmount !== expense.paidAmount ||
          row.listedAmount !== expense.listedAmount ||
          (!paidAmount.eq(expense.payment_history.payments.reduce((sum, p) => sum.add(new Decimal(p.amount)), new Decimal(0))))

        if (hasAnythingChanged) {
          revisePaymentHistory = true
        }
      }

      if (revisePaymentHistory) {
        newExpenseState.payment_history = {
          _version: '2024-12-24',
          payments: [{
            date: row.date,
            amount: paidAmount.toFixed(2)
          }]
        }
      }
    }
    
    console.log('newExpenseState', newExpenseState)

    dispatch(updateExpense(newExpenseState))
    if (onSubmit) {
      onSubmit(newExpenseState, addIncidentAction?.payload ?? null)
    }
  }

  const selectedPatient = present(row.patient_name) && present(row.patient_dob) &&
    patients.find((p) => p.full_name === row.patient_name && p.date_of_birth === row.patient_dob)

  return <form
    ref={formRef}
    className={`edit-expense-form ${wasValidated && 'was-validated'}`}
    onSubmit={(e) => {
      e.preventDefault()
    }}>
    <div className="input-group has-validation">
      <span className='input-group-text'>Date</span>
      <input name="datepic" placeholder="DateRange" type="date"
        className={`form-control ${validationErrors.date ? 'is-invalid' : ''}`}
        required
        disabled={disabledFields?.includes('date')}
        value={row.date || ''}
        onChange={(e) => { setRow({...row, date: e.target.value }) }}></input>
      {validationErrors.date && <div className="invalid-feedback show">{validationErrors.date}</div> }
    </div>
    <div className='input-group has-validation'>
      <span className='input-group-text'>Provider</span>
      <div className="form-control edit-expense-form__provider-select-form-control">
        <ProviderSelect
          className={validationErrors.provider ? 'is-invalid' : ''}
          disabled={disabledFields?.includes('provider')}
          value={row.provider ? {
            value: row.provider,
            id: row.provider_id || null
          } : null}
          onChange={(newValue) => {
            setRow({...row, provider: newValue?.value || null, provider_id: newValue?.id || null})
          }}
        />
      </div>
      {validationErrors.provider && 
        <div className="invalid-feedback show">{validationErrors.provider}</div>
      }
    </div>
    <div className='input-group has-validation'>
      <span className='input-group-text'>Patient</span>
      {patients && <PatientSelect
        className={`form-select ${validationErrors.patient_name ? 'is-invalid' : ''}`}
        options={patients}
        value={selectedPatient}
        disabled={disabledFields?.includes('patient_name') && disabledFields?.includes('patient_dob')}
        required
        onChange={({value}) => {
          if (!value) {
            setRow({
              ...row,
              patient_name: undefined,
              patient_dob: undefined,
              incident_id: undefined
            })
          } else if ('id' in value) {
            setRow({
              ...row,
              patient_name: value.full_name,
              patient_dob: value.date_of_birth,
              incident_id: null
            })
          }
        }} />}
      {validationErrors.patient_name && <div className="invalid-feedback show">{validationErrors.patient_name}</div> }
    </div>
    
    
    <div className='input-group'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>
          Pre-Discount Amount
        </span>
        <Tooltip  className='d-none d-md-inline ms-auto' tooltip="The listed amount before any discounts, such as the self-pay discount or financial aid" />
        <Tooltip className='d-inline d-md-none'
            tooltip="The listed amount before any discounts, such as the self-pay discount or financial aid">
          Pre-Discount
        </Tooltip>
          
      </label>
      <input name="listedAmount" type='text'
        className={`form-control ${validationErrors.listedAmount ? 'is-invalid' : ''}`}
        value={`$${row.listedAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('listedAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const listedAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, listedAmount})
          clearValidationError('listedAmount')
        }}></input>

      {validationErrors.listedAmount && <div className="invalid-feedback show">{validationErrors.listedAmount}</div> }
    </div>
    
    <div className='input-group'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>
         Discount Amount
        </span>
        <Tooltip  className='d-none d-md-inline ms-auto' tooltip="The total amount of discounts applied to this expense" />
        <Tooltip className='d-inline d-md-none'
            tooltip="The total amount of discounts applied to this expense">
          Discount
        </Tooltip>
          
      </label>
      <input name="discountAmount" type='text'
        className={`form-control ${validationErrors.discountAmount ? 'is-invalid' : ''}`}
        value={`$${row.discountAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('discountAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const discountAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, discountAmount})
          clearValidationError('discountAmount')
        }}></input>

      {validationErrors.discountAmount && <div className="invalid-feedback show">{validationErrors.discountAmount}</div> }
    </div>
    
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Amount You Already Paid</span>
        <Tooltip className='d-none d-md-inline ms-auto' tooltip="The total amount you have already paid for this expense.  If you have not paid anything yet, enter 0." />
        <Tooltip className='d-inline d-md-none'
            tooltip="The total amount you have already paid for this expense.  If you have not paid anything yet, enter 0.">
          Paid
        </Tooltip>
      </label>
      <input name="paidAmount" type='text'
        className={`form-control ${validationErrors.paidAmount ? 'is-invalid' : ''}`}
        value={`$${row.paidAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('paidAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const paidAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, paidAmount})
          clearValidationError('paidAmount')
        }}></input>
      {validationErrors.paidAmount && <div className="invalid-feedback show">{validationErrors.paidAmount}</div> }
    </div>
    
    <div className='input-group has-validation'>
      <label className='input-group-text'>
        <span className='d-none d-md-inline'>Amount You Owe</span>
        <Tooltip className='d-none d-md-inline ms-auto' tooltip="The amount you still owe after discounts and payments.  If you have not paid anything yet, enter the full amount here." />
        <Tooltip className='d-inline d-md-none'
            tooltip="The amount you still owe after discounts and payments.  If you have not paid anything yet, enter the full amount here.">
          Owed
        </Tooltip>
      </label>
      <input name="remainingAmount" type='text'
        className={`form-control ${validationErrors.remainingOwedAmount ? 'is-invalid' : ''}`}
        value={`$${row.remainingOwedAmount || ''}`}
        pattern='\$\d+(\.\d{0,2})?'
        disabled={disabledFields?.includes('remainingOwedAmount')}
        onBlur={onBlur}
        onChange={(e) => {
          const remainingOwedAmount = (e.target.value && parseDollarAmount(e.target.value)) || ''
          setRow({...row, remainingOwedAmount})
          clearValidationError('remainingOwedAmount')
        }}></input>
      {validationErrors.remainingOwedAmount && <div className="invalid-feedback show">{validationErrors.remainingOwedAmount}</div> }
    </div>

    {canChoosePaidWithHra &&
      <div className='input-group has-validation'>
        <div className='edit-expense-form__form-check-wrapper'>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_hra' value='hra'
              checked={row.paid_with_hra === true}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: true})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_hra">{hraCustomizations.hraCardLabel || 'HRA card'}</label>
          </div>
          <div className='form-check form-check-inline'>
            <input className='form-check-input' type='radio'
              name='paid_with' id='paid_with_personal' value='personal'
              checked={row.paid_with_hra === false}
              onChange={(e) => {
                if (e.target.checked) {
                  setRow({...row, paid_with_hra: false})
                }
              }}></input>
            <label className="form-check-label" htmlFor="paid_with_personal">Personal</label>
          </div>
        </div>

        <div className="invalid-feedback">
          Indicate whether you paid this expense with your HRA credit card
          or personal funds.
        </div>
      </div>}
      
    {incidentIsMaternity &&
      <div className="form-check mt-2">
        <input className="form-check-input" type="checkbox" id="is_pre_payment_agreement"
          checked={!!row.is_prepayment_agreement}
          onChange={(e) => {
            setRow({...row, is_prepayment_agreement: e.target.checked})
          }}
          />
        <label className="form-check-label" htmlFor="is_maternity">
            This is a Pre-payment Agreement
        </label>
      </div>}

    <div className="col-12 mt-2">
      <IncidentInputGroup
        allowNewIncident={true}
        required
        selectedPatient={selectedPatient}
        value={selectedIncident}
        onChange={setSelectedIncident} />
    </div>
    
    {children ?
      <div className="col-12 mt-2">
        {children}
      </div> :
      null
    }

    <div className="col-12 d-flex mt-4">
      
      <div className="me-auto">
        {isTextractPending(expense) ?
          isTextractJobFailed(expense) ?
            <Tooltip tooltip={"Unable to automatically extract expense information"}>
              <i className="material-icons text-danger">warning</i>
            </Tooltip> :
            <Tooltip tooltip="Scanning expense to extract information.  This can sometimes take a few minutes.  You can enter the information manually and it wont be overwritten.">
              <i className="material-icons flicker">sensors</i>
            </Tooltip> :
          null
        }
      </div>
      <button type='submit'
          className={`btn btn-lg btn-primary ms-auto`}
          onClick={doUpdate}>
        Save
      </button>
    </div>
  </form>
}

function expenseModelToFormModel(expense: ExpenseModel): () => EditExpenseFormModel {
  return () => {
    const discountAmount = expense.listedAmount && expense.paidAmount &&
      new Decimal(expense.listedAmount).minus(expense.paidAmount).toFixed(2)
    
    const postDiscountAmount = expense.listedAmount && discountAmount &&
      new Decimal(expense.listedAmount).sub(discountAmount).toFixed(2)
      
    let remainingOwedAmount = '0';
    
    let sumPaymentsMade: Decimal | undefined = undefined
    if (expense.paidAmount && expense.payment_history) {
      sumPaymentsMade = expense.payment_history.payments.reduce((acc, payment) => {
        return new Decimal(acc).plus(payment.amount)
      }, new Decimal(0))
      remainingOwedAmount = new Decimal(expense.paidAmount).minus(sumPaymentsMade).toFixed(2)
    }
    
    return {
      ...expense,
      
      // Legacy naming issue: "paidAmount" in ExpenseModel is the amount after discounts, while "paidAmount" in the form is the amount the user has actually paid
      // In the expense table, this information is stored in the "payment_history" field.
      // If not present, we assume the user has fully paid the post-discount amount of the expense.
      paidAmount: sumPaymentsMade?.toFixed(2) || postDiscountAmount,

      discountAmount,
      remainingOwedAmount
    }
  }
}

type CompleteExpenseFormModel = NotNull<EditExpenseFormModel, 'date' | 'patient_dob' | 'patient_name' | 'listedAmount'| 'discountAmount' | 'paidAmount' | 'remainingOwedAmount'>

function validateRow(row: Partial<EditExpenseFormModel>, formRef: React.RefObject<HTMLFormElement>, setValidationErrors: (errors: ValidationErrors) => void): row is CompleteExpenseFormModel {
  const validationErrors: ValidationErrors = {}
  let isInvalid = false
  
  if (!formRef?.current?.reportValidity()) {
    isInvalid = true
  }
  
  const listedAmount = tryParseDecimal(row.listedAmount)
  const discountAmount = tryParseDecimal(row.discountAmount)
  const paidAmount = tryParseDecimal(row.paidAmount)
  const remainingOwedAmount = tryParseDecimal(row.remainingOwedAmount)
  
  if (!row.patient_dob) { validationErrors.patient_dob = 'Please select a patient'; isInvalid = true;    }
  if (!row.patient_name) { validationErrors.patient_name = 'Please select a patient'; isInvalid = true;  }
  if (!row.date) { validationErrors.date = 'Please select a date'; isInvalid = true;  }

  if (!listedAmount) { validationErrors.listedAmount = 'Please enter a valid dollar amount'; isInvalid = true;  }
  if (listedAmount) {
    if (listedAmount.lessThan(0)) { validationErrors.listedAmount = 'Listed amount cannot be less than 0'; isInvalid = true;  }
  }
  if (!discountAmount) { validationErrors.discountAmount = 'Please enter a valid dollar amount'; isInvalid = true;  }
  if (discountAmount) {
    if (discountAmount.lessThan(0)) { validationErrors.discountAmount = 'Discount amount cannot be less than 0'; isInvalid = true;  }
  }
  if (!paidAmount) {validationErrors.paidAmount = 'Please enter a valid dollar amount'; isInvalid = true;  }
  if (paidAmount) {
    if (paidAmount.lessThan(0)) { validationErrors.paidAmount = 'Paid amount cannot be less than 0'; isInvalid = true;  }
  }
  if (!remainingOwedAmount) { validationErrors.remainingOwedAmount = 'Please enter a valid dollar amount'; isInvalid = true;    }
  if (remainingOwedAmount) {
    if (remainingOwedAmount.lessThan(0)) { validationErrors.remainingOwedAmount = 'Owed amount cannot be less than 0'; isInvalid = true;  }
  }
  
  // Check whether the amounts all total up correctly
  if (listedAmount && discountAmount && paidAmount && remainingOwedAmount) {
    if (!listedAmount.sub(discountAmount).sub(paidAmount).eq(remainingOwedAmount)) {
      validationErrors.remainingOwedAmount = 'The amounts do not total up correctly'
      isInvalid = true
    }
  }
  
  setValidationErrors(validationErrors)
  
  return !isInvalid
}

function tryParseDecimal(value: string | null | undefined): Decimal | undefined {
  if (!value) { return undefined }
  try {
    return new Decimal(value)
  } catch (e) {
    return undefined
  }
}
